/*
|--------------------
|      SINGLE
|--------------------
*/

#page-news-single {
    .single-livre-blanc {
        @include media-breakpoint-up(lg) {
            background-color: $light-grey;
        }
        @include media-breakpoint-down(lg) {
            position: relative;
            &:after {
                content: "";
                height: 50%;
                width: 100%;
                display: block;
                background-color: #f8fafc;
                position: absolute;
                top: 0;
                z-index: 0;
            }
        }
        .block-bb {
            position: relative;
            z-index: 1;
            display: block;
            .back {
                position: relative;
                @include media-breakpoint-down(lg) {
                    &.p-mob {
                        padding-left: 0px;
                        padding-top: 108px;
                    }
                }
                &:before {
                    content: "";
                    height: 16px;
                    width: 16px;
                    display: block;
                    float: left;
                    transform: rotate(90deg);
                    margin: 7px 7px 0px 0;
                    background-image: url(../img/icon/arr_b.svg);
                }
                &:after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    background: linear-gradient(90deg, rgba(203, 218, 235, 1) 0%, rgba(255, 255, 255, 0) 100%);
                }
                a {
                    color: $black;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-decoration-line: underline;
                }
            }
            .anchor-bb {
                display: none;
                @include media-breakpoint-down(sm) {
                    display: flex;
                }
                .anchor-bb-link {
                    color: $blue;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    img {
                        margin-left: 8px;
                    }
                }
            }
            .couv {
                img {
                    display: block;
                    max-width: 100%;
                    box-shadow:
                        -5px 11px 28px 0px rgba(14, 28, 44, 0.1),
                        -22px 46px 51px 0px rgba(14, 28, 44, 0.09),
                        -49px 103px 69px 0px rgba(14, 28, 44, 0.05);
                }
            }

            @include media-breakpoint-down(md) {
                .cms {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    p,
                    a,
                    li {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .single-container-livre-blanc {
        .block-contact {
            .contact-form {
                margin-top: -120px;
                .gform_footer {
                    &:after {
                        content: "";
                        background-image: url(../img/icon/ddl-w.svg);
                    }
                    @include media-breakpoint-down(md) {
                        input[type="submit"] {
                            padding: 8px 30px 8px 18px !important;
                        }
                    }
                }
            }
        }
    }
    .single-header {
        background-color: $light-grey;
        .back {
            position: relative;
            @include media-breakpoint-down(lg) {
                &.p-mob {
                    padding-left: 0px;
                    padding-top: 108px;
                }
            }
            &:before {
                content: "";
                height: 16px;
                width: 16px;
                display: block;
                float: left;
                transform: rotate(90deg);
                margin: 7px 7px 0px 0;
                background-image: url(../img/icon/arr_b.svg);
            }
            &:after {
                content: "";
                height: 1px;
                width: 100%;
                display: block;
                position: absolute;
                bottom: 0;
                background: linear-gradient(90deg, rgba(203, 218, 235, 1) 0%, rgba(255, 255, 255, 0) 100%);
            }
            a {
                color: $black;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-decoration-line: underline;
            }
        }
    }
    .single-container {
        .bg {
            height: 264px;
            width: 100%;
            display: block;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
            @include media-breakpoint-down(md) {
                height: 200px;
            }
            @include media-breakpoint-down(sm) {
                height: 150px;
            }
        }
        .infos {
            border-top: 1px solid #cbdaeb;
            border-bottom: 1px solid #cbdaeb;
            @include media-breakpoint-down(sm) {
                --gy: 24px;
            }
            .date,
            .place {
                align-items: inherit;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    flex: initial !important;
                }
                .icon {
                    height: 64px;
                    width: 64px;
                    border-radius: 50%;
                    background-color: #f2f6fa;
                    img {
                        height: 34px;
                        width: 34px;
                        display: block;
                    }
                }
                .info {
                    .label {
                        font-weight: 400;
                    }
                    .text {
                        font-weight: 600;
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .cms {
                    p,
                    a,
                    li {
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
        .btn-mob {
            @include media-breakpoint-down(md) {
                padding: 8px 40px 8px 18px !important;
                margin: auto;
                display: table;
                text-align: center;
            }
        }
    }
    .col-right {
        .col {
            border-radius: 8px;
            border: 1px solid #cbdaeb;
            background: white;
            align-items: inherit;
            .icon {
                height: 64px;
                width: 64px;
                border-radius: 50%;
                background-color: #f2f6fa;
                img {
                    height: 34px;
                    width: 34px;
                    display: block;
                }
            }
            h2 {
                @include media-breakpoint-down(xl) {
                    font-size: 18px;
                    line-height: 24px;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
            .info {
                .label {
                    font-weight: 400;
                }
                .text {
                    font-weight: 600;
                }
            }
            .gravity-form {
                .gform_fields {
                    grid-column-gap: initial;
                }
            }
        }
    }
}
