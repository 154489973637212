/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Set paddings & margins sizes properties
|
|
*/
$xs: 8px;
$sm: 16px;
$md: 32px;
$lg: 64px;
$xl: 80px;
$xxl: 120px;

$space-0: 0px;
$space-2xs: var(--space-2xs);
$space-xs: var(--space-xs);
$space-s: var(--space-s);
$space-m: var(--space-m);
$space-l: var(--space-l);
$space-xl: var(--space-xl);
$space-2xl: var(--space-2xl);
$space-3xl: var(--space-3xl);
$space-4xl: var(--space-4xl);
$space-5xl: var(--space-5xl);
$space-6xl: var(--space-6xl);
$space-7xl: var(--space-7xl);
$space-8xl: var(--space-8xl);
$space-9xl: var(--space-9xl);
$space-10xl: var(--space-10xl);

:root {
    --space-2xs: 4px;
    --space-xs: 8px;
    --space-s: 12px;
    --space-m: 16px;
    --space-l: 20px;
    --space-xl: 24px;
    --space-2xl: 32px;
    --space-3xl: 40px;
    --space-4xl: 48px;
    --space-5xl: 56px;
    --space-6xl: 64px;
    --space-7xl: 80px;
    --space-8xl: 96px;
    --space-9xl: 120px;
    --space-10xl: 144px;

    // @media (max-width: 712px) {
    //     --space-2xs: 3px;
    //     --space-xs: 6px;
    //     --space-s: 8px;
    //     --space-m: 12px;
    //     --space-l: 14px;
    //     --space-xl: 16px;
    //     --space-2xl: 24px;
    //     --space-3xl: 36px;
    //     --space-4xl: 42px;
    //     --space-5xl: 50px;
    //     --space-6xl: 58px;
    //     --space-7xl: 68px;
    //     --space-8xl: 84px;
    //     --space-9xl: 90px;
    //     --space-10xl: 110px;  
    // }
}