.block-shop-links {
    .shop {
        height: 100%;
        min-height: 585px;
        border-radius: 8px;
        border: 1px solid #cbdaeb;
        background: white;
        position: relative;

        @include media-breakpoint-down(lg) {
            height: 100%;
            min-height: initial;
        }

        .bg {
            height: 100%;
            width: 24px;
            display: block;
            background-size: cover;
            float: left;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            @include media-breakpoint-down(md) {
                width: 16px;
            }
        }

        .intro {
            h2 {
                font-weight: 600;
                @include media-breakpoint-up(lg) {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            .desc {
                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                    line-height: 28px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .links {
            display: block;
            li {
                border-top: 1px solid #cbdaeb;
                position: relative;
                &:after {
                    content: "";
                    height: 25px;
                    width: 25px;
                    background-size: cover;
                    background-image: url(../img/icon/arrow_right.svg);
                    display: inline-block;
                    position: absolute;
                    right: 15px;
                    top: calc(50% - 12px);
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    transition: all ease 0.3s;
                }
                &:hover {
                    &:after {
                        content: "";
                        right: 10px;
                    }
                }
                &:last-child {
                    border-bottom: 1px solid #cbdaeb;
                }
                a {
                    display: block;
                    &:hover {
                        background-color: $light-grey;
                    }
                    h3 {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        @include media-breakpoint-up(lg) {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .desc {
                        min-height: 48px;
                        color: $light-grey3;
                        font-size: 16px;
                        line-height: 24px;
                        @include media-breakpoint-up(lg) {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }
            }
        }

        .btn-link {
            @include media-breakpoint-down(lg) {
                display: block;
                margin-bottom: 50px;
            }
            @include media-breakpoint-down(md) {
                margin-bottom: initial;
            }
            .btn {
                float: right;
                position: absolute;
                bottom: 25px;
                right: 30px;
                @include media-breakpoint-down(md) {
                    position: relative;
                    bottom: initial;
                    right: initial;
                    padding: 8px 40px 8px 16px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
