.banner-flex {
    color: white;
    background-size: cover;
    background-position: center;
    background-color: $black2;
    overflow: hidden;
    padding: 140px 0 20px 0;
    @include media-breakpoint-down(md) {
        padding: 145px 0 0px 0;
    }
}