.btn {
    color: white;
    background-color: $blue;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px;
    transition: all ease 0.3s;
    position: relative;
    display: inline-block;
    padding: 14px 40px 14px 20px;
    border-radius: 8px;
    cursor: pointer;
    &:after {
        content: "";
        height: 24px;
        width: 24px;
        margin-left: 10px;
        margin-top: 0;
        display: inline-block;
        position: absolute;
        top: calc(50% - 12px);
        right: 10px;
        transition: all ease 0.3s;
        background-position: right;
        background-size: cover;
        background-image: url("../img/icon/plus.svg");
    }
    &:hover {
        background-color: $dark-blue;
        transition: all ease 0.3s;
    }
    &.btn-alt {
        &:after {
            content: "";
            background-image: url("../img/icon/arr_l_w.svg");
        }
    }
    &.btn-alt-w {
        color: $blue;
        background-color: white;
        &:after {
            content: "";
            background-image: url("../img/icon/arrow_right_blue.svg");
        }
        &:hover {
            color: #0e1d2c;
            &:after {
                content: "";
                background-image: url("../img/icon/arrow_right_dark_blue.svg");
            }
        }
    }
    &.btn-alt-b {
        color: white;
        border: 1px solid white;
        background-color: transparent;
        &:after {
            content: "";
            background-image: url("../img/icon/arrow_right_white.svg");
        }
        &:hover {
            background-color: #33425e;
        }
    }
    &.btn-alt-w-b {
        color: $blue;
        background-color: white;
        border: 1px solid $blue;
        &:after {
            content: "";
            background-image: url("../img/icon/arrow_right_blue.svg");
        }
        &:hover {
            color: #000;
            &:after {
                content: "";
                background-image: url("../img/icon/arrow_right_dark_blue.svg");
            }
        }
    }
    &.btn-ext {
        &:after {
            content: "";
            background-image: url(../img/icon/ext_link_w.svg);
        }
        &:hover {
            background-color: #0e1d2c;
        }
    }
    &.btn-up {
        &:after {
            content: "";
            background-image: url(../img/icon/ddl-w.svg);
        }
        &:hover {
            background-color: #0e1d2c;
        }
    }
}
