.block-faq {
    .accordion {
        .accordion-item {
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            .accordion-item-header {
                border-radius: 8px;
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                padding-right: 45px;
                background-color: white;
                position: relative;
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                    line-height: 24px;
                    padding-right: 55px;
                }
                &:after {
                    content: "";
                    height: 24px;
                    width: 24px;
                    border-radius: 4px;
                    background-size: cover;
                    background-image: url(../img/icon/arr_b_big.svg);
                    transform: rotate(90deg);
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 10px);
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    transition: all ease 0.3s;
                }
                &.active {
                    &:after {
                        transform: rotate(270deg);
                    }
                }
            }
            .accordion-item-body {
                border-radius: 8px;
                background-color: white;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s ease-out;
                .accordion-item-body-content {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                }
            }
        }
    }
}
