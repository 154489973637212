/*
|-------------------------------
| Margins & Paddings classes creation
|-------------------------------
| Variables used to generate padding & margin classes
| - Generated classes: .m{direction}-{size} & .p{direction}-{size}
| - Exemple: .mt-md & .pt-md
|
*/
$spaces: (
    "0": 0,
    "xs": $xs,
    "sm": $sm,
    "md": $md,
    "lg": $lg,
    "xl": $xl,
    "xxl": $xxl,
    "fluid": var(--side-margin),
    "space-0": $space-0,
    "space-2xs": $space-2xs,
    "space-xs": $space-xs,
    "space-s": $space-s,
    "space-m": $space-m,
    "space-l": $space-l,
    "space-xl": $space-xl,
    "space-2xl": $space-2xl,
    "space-3xl": $space-3xl,
    "space-4xl": $space-4xl,
    "space-5xl": $space-5xl,
    "space-6xl": $space-6xl,
    "space-7xl": $space-7xl,
    "space-8xl": $space-8xl,
    "space-9xl": $space-9xl,
    "space-10xl": $space-10xl
);

$space-properties: (
    "m": "margin",
    "mt": "margin-top",
    "mb": "margin-bottom",
    "my": "margin-block",
    "ml": "margin-left",
    "mr": "margin-right",
    "mx": "margin-inline",
    "p": "padding",
    "pt": "padding-top",
    "pb": "padding-bottom",
    "py": "padding-block",
    "pl": "padding-left",
    "pr": "padding-right",
    "px": "padding-inline"
);
