/*
|---------------------------------------------------------------
| GRID
|---------------------------------------------------------------
| Set grid properties
|
|
*/
$grid-default: 12 !default;
$grid-col-gap: var(--grid-col-gap) !default;
$grid-row-gap: 48px !default;

:root {
    --grid-col-gap: 48px;

    @media (max-width: 712px) {
        --grid-col-gap: 32px;
    }
}