/*
|-------------
| Breakpoints media queries creation 
|-------------
| - How to use: add lines to create more breakpoints
|
*/
$breakpoints: (
    sm: 712px,
    md: 712px,
    lg: 960px,
    xl: 1232px,
    xxl: 90rem
);
