.block-confiance {
    position: relative;
    .block {
        width: 100%;
        .tabs {
            margin: auto;
            .tabs-nav {
                border-radius: 8px;
                border: 1px solid #cbdaeb;
                display: flex;
                padding: 4px;
                max-width: 386px;
                margin: 0 auto 40px auto;
                background: white;
                li {
                    float: left;
                    border-radius: 8px;
                    padding: 0 2px;
                    width: 100%;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    a {
                        color: $blue;
                        background-color: white;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: center;
                        text-decoration: none;
                        transition: all ease 0.3s;
                        border-radius: 8px;
                        width: 100%;
                        display: block;
                    }
                    &:hover,
                    &.tab-active {
                        a {
                            color: white;
                            background-color: $blue;
                            transition: all ease 0.3s;
                        }
                    }
                }
            }
        }

        .swiper-partenaires {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            overflow: hidden;
            .swiper-wrapper {
                transition-timing-function: linear;
                .swiper-slide {
                    position: relative;
                    .item {
                        background-color: $light-grey;
                        border-radius: 8px;
                        display: inline-flex;
                        img {
                            max-height: 50px;
                            max-width: 140px;
                        }
                    }
                }
            }
        }
        .no-slider {
            .col-auto {
                display: contents;
                a {
                    display: block;
                    margin: auto;
                    min-width: 184px;
                    border-radius: 20px;
                    background-color: $light-grey;
                    span {
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        img {
                            margin: auto;
                            max-height: 50px;
                        }
                    }
                }
            }
        }
    }
    &.bg_grey {
        .swiper-slide {
            .item {
                background-color: white !important;
            }
        }
        .no-slider {
            a {
                background-color: white !important;
            }
        }
    }
}
