.hero {
    display: flex;
    width: 100%;
    min-height: 100%;
    position: relative;
    align-items: center;
    padding-block: var(--header-height);
    background-color: $black2;
    overflow: hidden;
    padding: 143px 0 80px 0;
    @if is-enabled("background-images") {
        @extend .bg-cover;
    }
    img {
        max-width: 100%;
        width: 600px;
        @include media-breakpoint-down(xl) {
            max-width: 380px;
            width: 100%;
        }
    }
}
