.cms {
    font-size: 18px;
    line-height: 28px;
    word-break: break-word;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    p:not(:last-child) {
        margin-bottom: 24px;
    }

    p,
    a,
    li {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }

    h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    img {
        display: block;
    }
    .aligncenter {
        margin: auto;
    }
    ul {
        padding: 0 0 15px 25px;
        list-style: none;
        li {
            position: relative;
            &::before {
                content: "";
                height: 4px;
                width: 4px;
                display: block;
                border-radius: 50%;
                background-color: black;
                position: absolute;
                left: -14px;
                top: 12px;
            }
        }
    }

    ol {
        padding: 0 0 15px 25px;
        list-style: none;
        li {
            list-style-type: auto;
            position: relative;
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }

    blockquote {
        position: relative;
        width: 80%;
        margin: 60px auto;
        padding: 0;
        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &:before {
            content: "";

            position: absolute;
            left: -60px;
            top: -40px;

            background-image: url("../img/icon/quote-left.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            @include media-breakpoint-down(md) {
                left: 0px;
                top: -50px;
            }
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;
            bottom: -40px;

            background-image: url("../img/icon/quote-right.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }
    iframe {
        max-width: 100%;
    }
}

.content-cms {
    .block-sommaire {
        position: relative;
        @include media-breakpoint-down(lg) {
            --gy: 8px;
        }
        h2 {
            @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .left-column {
            .sommaire {
                font-size: 16px;
                line-height: 24px;
                margin-top: 25px;
                position: relative;
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
                .headingM {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
                .option-mob {
                    display: none;
                }

                li {
                    a {
                        border-radius: 4px;
                        padding: 12px 27px 12px 12px;
                        position: relative;
                        display: block;
                        transition: all ease 0.3s;
                        @include media-breakpoint-down(xl) {
                            padding: 8px 27px 8px 12px;
                        }
                        &:after {
                            content: "";
                            height: 20px;
                            width: 20px;
                            border-radius: 4px;
                            background-image: url(../img/icon/arr_b_big.svg);
                            display: inline-block;
                            position: absolute;
                            right: 10px;
                            top: calc(50% - 10px);
                            display: flex;
                            vertical-align: middle;
                            align-items: center;
                            transition: all ease 0.3s;
                        }
                        &:hover {
                            background-color: $light-grey;
                            transition: all ease 0.3s;
                            &:after {
                                content: "";
                                right: 5px;
                                transition: all ease 0.3s;
                            }
                        }
                    }
                }

                @media screen and (max-width: 960px) {
                    &::before {
                        content: "";
                        background-image: url("../img/icon/summary.svg");
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        bottom: 0;
                        transform: translateY(-50%);
                        height: 32px;
                        width: 40px;
                        background-position: left 0 center;
                        left: 18px;
                        z-index: 1;
                    }
                    &::after {
                        //content: "";
                        height: 24px;
                        width: 24px;
                        display: block;
                        right: 20px;
                        top: 30px;
                        position: absolute;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-image: url("../img/icon/arr_b.svg");
                    }
                    h2 {
                        display: none;
                    }
                    .option-mob {
                        display: block;
                    }
                    .sommary {
                        display: none;
                    }
                    select {
                        -webkit-appearance: none;
                        appearance: none;
                        border-radius: 8px;
                        border: 1px solid #cbdaeb;
                        background: #fff url("../img/icon/arr_b.svg") no-repeat right 20px center;
                        padding: 16px 60px 16px 60px;
                        width: 100%;
                        margin-top: 10px;
                        position: relative;
                        min-height: 62px;

                        color: #0e1c2c;
                        font-family: Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}
