/*
|--------------------
|      Metiers
|--------------------
*/

#single-metiers {
    .bg_grey {
        padding-top: 120px;
    }
    .banner-metiers {
        background-color: #f9fafb;
        .img-bg {
            border-radius: 0.25rem;
            background-size: cover;
            width: 100%;
            max-width: 466px;
            max-height: 560px;
            aspect-ratio: 2.2 / 3;
            overflow: hidden;
        }
        .label-cat {
            font-size: 12px;
            font-weight: 600;
            line-height: 10px;
            padding: 8px 7px;
            border-radius: 4px;
            display: inline-block;
            background-color: #cbdaeb;
            margin-bottom: 15px;
        }
        .desc-post {
            color: #0e1c2c;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.75rem;
        }
    }
    .infos {
        position: relative;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: $light-grey;
            position: absolute;
            left: 0;
            right: 0;
            top: calc(-50%);
            bottom: 0;
            @include media-breakpoint-down(lg) {
                position: relative;
            }
        }
        .bloc-infos {
            border-radius: 8px;
            background: white;
            z-index: 1;
            position: relative;
            display: flex;
            --container-width: calc(1232px + 48px);
            box-shadow:
                0px 14px 64px -4px rgba(13, 30, 70, 0.08),
                0px 8px 22px -6px rgba(13, 30, 70, 0.04);
            .item {
                position: relative;
                @include media-breakpoint-down(lg) {
                    height: initial;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -24px;
                    height: 100%;
                    width: 1px;
                    background-color: #cbdaeb;

                    @include media-breakpoint-down(lg) {
                        position: absolute;
                        top: initial;
                        bottom: -20px;
                        left: 0;
                        right: initial;
                        height: 1px;
                        width: 100%;
                        background-color: #cbdaeb;
                    }
                }
                &:last-child {
                    &:after {
                        content: "";
                        background: none;
                    }
                }
                h2 {
                    font-weight: 600;
                }
                .icone {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 6rem;
                    background: #f1f5f9;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    img{
                        margin: auto;
                        width: 100%;
                        max-width: 28px;
                    }
                }
                .desc {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    color: #616F7D;
                    @include media-breakpoint-down(lg) {
                        -webkit-line-clamp: initial;
                        margin-bottom: 20px;
                    }
                }
                .figure {
                    color: #0E1C2C;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }
    }
    .similarmetiers {
        padding: 60px 0;
        .menu-market {
            margin-top: 0 !important;
        }
    }
}
#page-metiers-archive {
    .breadcrumb {
        padding: 20px 0 40px 0;
    }
    .list {
        .elem {
            border-radius: 0.5rem;
            border: 1px solid #cbdaeb;
            background: #fff;
            padding: 1.5rem;
            img {
                float: left;
                margin-right: 10px;
            }
            .cat-title {
                color: #0e1c2c;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 2rem;
                padding-bottom: 15px;
                display: flex;
                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
            .post-title {
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 400;
                display: block;
                position: relative;
                padding: 12px 10px 10px 10px;
                transition: all ease 0.3s;
                border-radius: 4px;
                position: relative;
                @include media-breakpoint-down(md) {
                    font-size: 0.8rem;
                    line-height: 1.5rem;
                }
                &:hover {
                    background:#eff4f8;
                    transition: all ease 0.3s;
                    svg {
                        right: 5px;
                        position: absolute;
                        transition: all ease 0.3s;
                    }
                }
                svg {
                    right: 8px;
                    position: absolute;
                    transition: all ease 0.3s;
                }
            }
        }
    }
    .hidden {
        display: none;
    }
    .btn-see-more {
        svg {
            float: left;
            margin-top: 2px;
            margin-right: 5px;
        }
        span {
            color: #225cb5;
            display: inline-block;
        }
        &:hover{
            color: #0E1C2C;
        }
    }
}
