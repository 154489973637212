/*
|---------------------------------------------------------------
| FONTS
|---------------------------------------------------------------
| Set all font properties
|
|
*/

/*
|
| FAMILIES
|----------------
|
*/
$font-family-default: "Poppins", sans-serif;
$font-family-custom: "Poppins", sans-serif;
$font-family-extra: "Poppins", sans-serif;

/*
|
| SIZES
|----------------
|
*/
$font-size-xs: 0.75rem; // 12px
$screen-xl-font-size-xs: $font-size-xs; // 12px
$screen-lg-font-size-xs: $font-size-xs; // 12px
$screen-md-font-size-xs: $font-size-xs; // 12px
$screen-sm-font-size-xs: $font-size-xs; // 12px

$font-size-sm: 1rem; // 16px
$screen-xl-font-size-sm: $font-size-sm; // 16px
$screen-lg-font-size-sm: $font-size-sm; // 16px
$screen-md-font-size-sm: $font-size-sm; // 16px
$screen-sm-font-size-sm: $font-size-sm; // 16px

$font-size-md: 1.125rem; // 18px
$screen-xl-font-size-md: $font-size-md; // 18px
$screen-lg-font-size-md: $font-size-md; // 18px
$screen-md-font-size-md: $font-size-md; // 18px
$screen-sm-font-size-md: $font-size-md; // 18px

$font-size-lg: 1.75rem; // 28px
$screen-xl-font-size-lg: $font-size-lg; // 28px
$screen-lg-font-size-lg: $font-size-lg; // 28px
$screen-md-font-size-lg: $font-size-lg; // 28px
$screen-sm-font-size-lg: $font-size-lg; // 28px

$font-size-xl: 2.25rem; // 36px
$screen-xl-font-size-xl: $font-size-xl; // 36px
$screen-lg-font-size-xl: $font-size-xl; // 36px
$screen-md-font-size-xl: $font-size-xl; // 36px
$screen-sm-font-size-xl: $font-size-xl; // 36px

$font-size-xxl: 2.25rem; // 36px
$screen-xl-font-size-xxl: $font-size-xxl; // 36px
$screen-lg-font-size-xxl: $font-size-xxl; // 36px
$screen-md-font-size-xxl: $font-size-xxl; // 36px
$screen-sm-font-size-xxl: $font-size-xxl; // 36px

/*
|
| LINE-HEIGHT
|----------------
|
*/
$line-height-xs: 1;
$line-height-sm: 1.2;
$line-height-md: 1.5;
$line-height-lg: 1.6;
$line-height-xl: 1.8;
$line-height-xxl: 2;

/*
|
| LETTER-SPACING
|----------------
|
*/
$letter-spacing-xs: 1px;
$letter-spacing-sm: 2px;
$letter-spacing-md: 3px;
$letter-spacing-lg: 4px;
$letter-spacing-xl: 5px;
$letter-spacing-xxl: 6px;

/*
|
| DEFAULTS
|----------------
|
*/
$default-font-size: $font-size-sm;
$default-color: $text-color;
$default-line-height: $line-height-xl;
$default-font-weight: 200;
