.pagination {
    --col-gap: 10px;
    --row-gap: 10px;

    display: flex;
    width: calc(100% + var(--col-gap));

    margin: 0 calc(-1 * var(--col-gap)) calc(-1 * var(--row-gap)) 0;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
        margin-top: var(--content-margin);
    }

    &:not(:last-child) {
        margin-bottom: calc(var(--content-margin) - var(--row-gap));
    }

    &-item {
        display: block;

        margin: 0 var(--col-gap) var(--row-gap) 0;
    }

    &-link,
    &-arrow {
        display: flex;
        width: 40px;
        aspect-ratio: 1/1;

        border: 1px solid #cbdaeb;
        border-radius: 100%;

        justify-content: center;
        align-items: center;

        font-weight: 700;
        color: $black;
    }
    &-arrow {
        position: relative;
        &:after {
            content: "";
            height: 25px;
            width: 25px;
            position: absolute;
            right: calc(50% - 12.5px);
            bottom: calc(50% - 12.5px);
            transition: all ease 0.3s;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(../img/icon/arr_b.svg);
        }
        &-left {
            &:after {
                content: "";
                transform: rotate(90deg);
            }
        }

        &-right {
            &:after {
                content: "";
                transform: rotate(270deg);
            }
        }
    }

    &-link {
        &--current {
            border-color: $blue;

            background-color: $blue;

            color: $white;
        }
    }
}
