.tti {
    position: relative;

    &-image {
        width: 100%;
        max-width: 466px;
        max-height: 560px;
        aspect-ratio: 2.2/3;
        overflow: hidden;
        float: right;
        @include media-breakpoint-down(lg) {
            float: left;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 7px;
        }
    }
    .inverse {
        .tti-image {
            float: left;
        }
    }

    &-item {
        gap: 24px;

        &-number {
            color: transparent;
            font-size: 6.875rem;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            -webkit-text-stroke: 1px $blue;

            min-width: 64px;
        }

        &-title {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }
    .tti-image-bg {
        .bg {
            border-radius: 8px;
            height: 180px;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        img {
            width: 100%;
            max-width: 410px;
            @include media-breakpoint-down(md) {
                max-height: 180px;
            }
        }
    }
}
