.filters {
    --col-gap: 15px;

    display: flex;
    width: calc(100% + var(--col-gap));

    margin: 0 calc(-1 * var(--col-gap)) calc(-1 * var(--row-gap)) 0;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    color: $blue;
    @include media-breakpoint-down(lg) {
        margin-left: 0;
        margin-right: 0;
        padding: 0 !important;
        justify-content: initial;
    }

    .txt-filter {
        font-size: 16px;
        line-height: 24px;
        color: $black;
    }

    &-item {
        width: calc(100% / var(--per-row) - var(--col-gap));
        max-width: 300px;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &-search {
        --per-row: 2;

        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        &-icon {
            font-size: 1.125rem;
        }

        &-field {
            display: block;
            width: 100%;

            padding: 10px 0 10px calc(1.125rem + 20px);
            border-bottom: 1px solid $blue;
            margin: 0 0 0 -1.125rem;

            font-size: 16px;
            line-height: 24px;

            &:focus {
                border-color: $blue;
            }
        }
    }

    &-select {
        --per-row: 3;

        display: block;

        position: relative;
        z-index: 1;

        &::after {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            position: absolute;
            top: calc(50% - 8px);
            right: 15px;
            z-index: -1;
            background-image: url(../img/icon/select.svg);
        }

        &-inner {
            display: block;
            width: 100%;

            padding: 12px;
            border-radius: 8px;
            border: 1px solid #cbdaeb;

            font-size: 16px;
            line-height: 24px;
            color: #89949f;

            cursor: pointer;

            &:focus {
                border-color: $blue;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;

        &-item {
            --per-row: 1;
        }
    }
}
