.block-decouvrez {
    position: relative;
    .bgi {
        position: absolute;
        top: 0;
    }
    .block {
        border-radius: 8px;
        background: white;
        box-shadow:
            0px 14px 64px -4px rgba(13, 30, 70, 0.08),
            0px 8px 22px -6px rgba(13, 30, 70, 0.04);
        .desc {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
