/* Gravity form */
.gravity-form {
    .gform_confirmation_message {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #0f1214;
        min-height: 200px;
        padding-top: 70px;
        text-align: center;
    }

    .gform_wrapper {
        .validation_message {
            font-family: inherit;
            text-transform: inherit;
        }
        .validation_message {
            padding: 3px 10px;
        }
        .gform_ajax_spinner {
            position: absolute;
            margin-top: 5px;
            display: none;
        }
        .gform_submission_error {
            text-transform: initial;
            font-size: 15px;
            font-weight: 700;
            &:after {
                content: none;
            }
            span {
                display: none;
            }
        }
        .gform_validation_errors {
            margin: 50px 50px 0px 50px;
            position: relative;
            width: calc(100% - 60px);
            padding: 10px;
            box-shadow: none;
            border: 1px solid black;
            border-radius: initial;
            display: none;
        }
        .gform_heading {
            display: none;
        }
        .gform-icon {
            display: none !important;
        }
        .gform_body {
            h2 {
                @include media-breakpoint-down(xl) {
                    font-size: 38px;
                    line-height: 43px;
                }
                &:after {
                    content: none;
                }
            }
            .gfield--type-textarea {
                position: relative;
            }
            .gform_required_legend {
                display: none;
            }
            .gform_title {
                display: none;
            }
            .gfield {
                margin-bottom: 10px;
                font-style: normal;
                font-size: 17px;
                line-height: 22px;
                color: #061c3d;
            }
            .gform_fields {
                grid-column-gap: 32px;
                @include media-breakpoint-down(md) {
                    grid-column-gap: 10px;
                }
            }
            .gfield_label {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: $black;
            }
            .gfield--type-consent {
                border: none;
            }
            .ginput_container_date {
                .datepicker {
                    width: 100%;
                }
            }
            .charleft {
                text-align: right;
            }

            .gfield_date_dropdown_day,
            .gfield_date_dropdown_month,
            .gfield_date_dropdown_year {
                max-width: 33.33%;
            }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            select,
            textarea {
                padding: 12px;
                font-size: 16px !important;
                line-height: initial !important;
                border-radius: 8px;
                border: 1px solid #cbdaeb;
                transition: all ease 0.2s;
                &::placeholder {
                    color: #89949f;
                }
                @include media-breakpoint-down(md) {
                }
                &:focus {
                    border: 1px solid #7ca8df;
                    outline: none;
                    transition: all ease 0.2s;
                }
            }
            .ginput_container_phone {
                input {
                    border-radius: 5px;
                    font-size: 16px !important;
                    line-height: initial;
                }
            }
            textarea {
                height: 144px !important;
            }
            select {
                color: #89949f;
                position: relative;
                background-repeat: no-repeat;
                background-position: calc(100% - 10px) center;
                background-image: url("../img/icon/arr_b.svg");
            }
            .ginput_container {
                .gfield_consent_label {
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 20px;
                    color: #0f1214;
                    margin-left: 28px;
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .gfield_radio {
                display: flex;
                vertical-align: middle;
                align-items: center;
                .gchoice {
                    position: relative;
                    input[type="radio"] {
                        cursor: pointer;
                        border: none;
                        left: 0;
                        top: 0px;
                        float: right;
                        padding: 0 !important;
                        margin: 1px 22px 1px 7px;
                        &:before {
                            content: "";
                            display: block;
                            position: relative;
                            width: 21px;
                            height: 21px;
                            top: 0;
                            left: 0;
                            border: 2px solid #3d4a57;
                            border-radius: 50%;
                            background-color: transparent;
                            float: right;
                        }
                        &:checked:after {
                            content: "";
                            position: absolute;
                            top: 5px;
                            right: 26px;
                            display: inline-block;
                            background-color: $dark-blue;
                            border-radius: 50%;
                            height: 13px;
                            width: 13px;
                        }
                    }
                }
            }
            .ginput_container_consent {
                position: relative;
                input[type="checkbox"] {
                    position: absolute;
                    cursor: pointer;
                    border: none;
                    left: 0;
                    top: 0px;
                    padding: 0 !important;
                    margin: 0;
                }

                input[type="checkbox"]:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 21px;
                    height: 21px;
                    top: 0;
                    left: 0;
                    border: 2px solid blue;
                    border-radius: 0px;
                    background-color: transparent;
                }
                input[type="checkbox"]:checked {
                    background-color: black;
                    height: 20px;
                    width: 20px;
                    border-radius: 0px;
                }
                input[type="checkbox"]:checked:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 7px;
                    border: solid white;
                    border-width: 0px 2px 2px 0;
                    display: inline-block;
                    padding: 3px 2px 8px 3px;
                    transform: rotate(45deg);
                }
            }
            .ginput_container_checkbox {
                position: relative;
                .gchoice {
                    cursor: pointer;
                    position: relative;
                }
                label {
                    cursor: pointer;
                    margin-left: 27px;
                }
                input[type="checkbox"] {
                    position: absolute;
                    cursor: pointer;
                    border: none;
                    left: 0;
                    top: 0px;
                    padding: 0 !important;
                    margin: 0;
                }

                input[type="checkbox"]:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 21px;
                    height: 21px;
                    top: 0;
                    left: 0;
                    border: 2px solid black;
                    border-radius: 0px;
                    background-color: transparent;
                }
                input[type="checkbox"]:checked {
                    background-color: blue;
                    height: 20px;
                    width: 20px;
                    border-radius: 0px;
                }
                input[type="checkbox"]:checked:before {
                    content: "";
                    border: 2px solid blue;
                }
                input[type="checkbox"]:checked:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 7px;
                    border: solid white;
                    border-width: 0px 2px 2px 0;
                    display: inline-block;
                    padding: 3px 2px 8px 3px;
                    transform: rotate(45deg);
                }
            }
            .champs-obligatoires {
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 32px;
            }
            .gfield--type-html {
                font-size: 12px;
                line-height: 16px;
                color: #616f7d;
            }
        }
        .gform_footer {
            width: fit-content !important;
            margin: 30px auto 0 auto !important;
            padding: 0 20px 0 0 !important;
            border: 1px solid #225cb5;
            background-color: $blue;
            position: relative;
            padding: 13px 20px;
            transition: all ease 0.3s;
            color: white;
            border-radius: 8px;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            display: block !important;
            cursor: pointer;
            position: relative;
            &:after {
                content: "";
                background-image: url(../img/icon/arrow_right_w.svg);
                height: 20px;
                width: 20px;
                background-size: cover;
                position: absolute;
                right: 15px;
                top: calc(50% - 10px);
                display: flex;
                vertical-align: middle;
                align-items: center;
                transition: all ease 0.3s;
                float: right;
            }
            &:hover {
                color: white;
                background-color: $dark-blue;
                &:after {
                    content: "";
                    right: 10px;
                    transition: all ease 0.3s;
                }
            }
            input[type="submit"],
            button[type="submit"] {
                padding: 12px 30px 12px 20px;
                margin: 0;
                font-weight: 600;
                cursor: pointer;
            }
        }
        .iti.iti--allow-dropdown input {
            border: none !important;
            border: 1px solid $dark-grey;
        }
        .required {
            font-size: 12px;
        }
    }

    .gform_wrapper.gravity-theme .gfield_validation_message,
    .gform_wrapper.gravity-theme .validation_message {
        color: #c02b0a !important;

        padding: 0 !important;

        background-color: transparent !important;
        border: none !important;
    }

    .gform_wrapper.gravity-theme .gfield_error [aria-invalid="true"] {
        background-color: #fcf7f7;
        border-color: #c02b0a !important;
    }

    /* Custom */
    .gform_wrapper {
        .gravity-theme {
            .gfield_label {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400 !important;
                font-size: 16px !important;
                line-height: 25px;
                color: #060f23;
            }
        }
        .gfield_required {
            color: blue !important;
        }
        .gfield_description {
            font-family: "Poppins" !important;
            font-style: normal;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 18px !important;
            color: $dark-grey !important;
        }
    }
}
.infos-form {
    color: $light-grey3;
    font-size: 10px;
    line-height: 16px;
    p {
        padding: 0 0 10px 0;
    }
    strong {
        color: $black;
    }
    a {
        text-decoration: underline;
    }
}
.gform_wrapper.gravity-theme .gfield_error label {
    color: #0e1c2c !important;
}
.gravity-form .gform_wrapper.gravity-theme .gfield_validation_message,
.gravity-form .gform_wrapper.gravity-theme .validation_message {
    background-color: #fcf7f7;
    position: relative;
    display: flex;
    vertical-align: middle;
    align-items: center;
    &:before {
        content: "";
        height: 16px;
        width: 16px;
        display: inline-block;
        background-size: cover;
        margin-right: 5px;
        min-width: 16px;
        background-image: url("../img/icon/alert.svg");
    }
}

.gform_wrapper.gravity-theme .description,
.gform_wrapper.gravity-theme .gfield_description,
.gform_wrapper.gravity-theme .gsection_description,
.gform_wrapper.gravity-theme .instruction {
    text-align: right;
    position: absolute;
    padding-top: 4px;
}

.ginput_container_select {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: 1;
        height: 46px;
        width: 40px;
        background-image: url("../img/icon/select.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
    }
}

.form-salesforce {
    margin-bottom: 30px;
    input,
    textarea,
    select {
        font-style: normal;
        font-size: 17px !important;
        line-height: 22px !important;
        color: #061c3d;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #cbdaeb;
        transition: all ease 0.2s;
        display: block;
        width: 100%;
        margin-top: 11px;
    }
    .select-container {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 30px;

        select {
            margin-top: 11px;
            background-color: #fff;
            appearance: none;
            cursor: pointer;
            padding-right: 40px;

            &:hover {
                border-color: #b3c7e0;
            }

            &:focus {
                border-color: #829ab1;
                outline: none;
                box-shadow: 0 0 5px rgba(130, 154, 177, 0.5);
            }

            &:disabled {
                background-color: #f5f5f5;
                color: #a9a9a9;
                cursor: not-allowed;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 59%;
            right: 12px;
            transform: translateY(-50%);
            height: 46px;
            width: 26px;
            background-image: url(../img/icon/select.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }
    textarea {
        height: 160px;
    }
    input[type="submit"],
    button[type="submit"] {
        border: 1px solid #225cb5;
        background-color: #225cb5;
        position: relative;
        padding: 13px 20px;
        transition: all ease 0.3s;
        color: white;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        display: block !important;
        cursor: pointer;
        position: relative;
        margin: 0;
        font-weight: 600;
        cursor: pointer;
        width: auto;
        padding: 12px 40px;
        transition: all ease 0.3s;
        &:hover {
            background-color: #153c89;
            transition: all ease 0.3s;
        }
    }
}
figure {
    margin: 30px auto 20px auto;
    figcaption {
        font-size: 15px;
        line-height: 18px;
        padding: 6px 0;
    }
}
