.block-chiffres {
    .nbr-chiffres {
        text-align: center;
        @include media-breakpoint-down(md) {
            text-align: left;
            --gy: 0px;
            .col-auto {
                width: 100%;
                flex: initial !important;
            }
        }
        .icon {
            height: 96px;
            width: 96px;
            padding: 16px;
            background-color: #f1f5f9;
            margin: auto;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
            }

            @include media-breakpoint-down(lg) {
                height: 80px;
                width: 80px;
                padding: 12px;
            }

            @include media-breakpoint-down(md) {
                height: 56px;
                width: 56px;
                padding: 8px;
            }

            @include media-breakpoint-down(md) {
                float: left;
                margin-right: 1rem;
                margin-bottom: 0;
                margin-top: 5px;
            }
        }

        .chiffre {
            color: $blue;
            font-weight: 600;
            @include media-breakpoint-down(lg) {
                font-size: 32px;
                line-height: 40px;
            }
        }
        .unity {
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            @include media-breakpoint-down(lg) {
                font-size: 16px;
                line-height: 24px;
            }
            @include media-breakpoint-down(md) {
                text-align: left;
                margin-left: 72px;
            }
        }
    }
    &.bg_grey {
        .nbr-chiffres {
            .icon {
                background-color: #ffffff;
            }
        }
    }
}
