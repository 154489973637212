.menu-home {
    z-index: 0;
    position: relative;
    border-radius: 8px;
    background-color: white;
    box-shadow:
        0px 14px 64px -25px rgba(13, 30, 70, 0.08),
        0px 8px 22px -6px rgba(13, 30, 70, 0.04);
    --container-width: calc(1232px + 48px);
    @media (max-width: 960px) {
        --container-width: calc(960px + 48px);
    }
    @media (max-width: 712px) {
        --container-width: calc(712px + 48px);
    }
    @include media-breakpoint-down(xl) {
        margin-top: -80px !important;
    }
    @include media-breakpoint-down(sm) {
        width: calc(100% - 30px);
        margin: auto;
    }
    &.active-ssmenu {
        .menu-sshome {
            display: block;
        }
    }
    .menu-parent {
        align-items: initial;
        background-color: white;
        border-radius: 8px;
        z-index: 2;
        display: flex;
        vertical-align: middle;
        @include media-breakpoint-down(xl) {
            display: block;
            padding: 24px;
        }
        @include media-breakpoint-down(lg) {
            padding: 8px;
        }
        a {
            transition: all ease 0.3s;
            width: inherit;
            position: relative;
            @include media-breakpoint-down(xl) {
                width: 50%;
                float: left;
                display: flex;
                vertical-align: middle;
                align-items: center;
                border-radius: 8px;
                padding: 20px 40px 20px 20px;
                min-height: 80px;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                min-height: initial;
            }
            &:after {
                content: "";
                opacity: 0;
                height: 25px;
                width: 25px;
                background-image: url(../img/icon/arrow_right.svg);
                background-position: 0 64px;
                background-repeat: no-repeat;
                position: absolute;
                right: 5px;
                bottom: 0;
                top: 0;
                height: auto;
                display: flex;
                vertical-align: middle;
                align-items: center;
                transition: all ease 0.3s;
                @include media-breakpoint-down(xl) {
                    content: "";
                    height: 20px;
                    width: 20px;
                    background-image: url(../img/icon/arr_b_big.svg);
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 10px);
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    transition: all ease 0.3s;
                    opacity: 1;
                    background-size: cover;
                    background-position: center;
                    background-color: transparent;
                }
            }
            .bg {
                height: 24px;
                width: 24px;
                display: block;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                @include media-breakpoint-down(xl) {
                    min-width: 24px;
                    margin-right: 8px;
                    margin-bottom: 0;
                }
            }
            .title {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                display: inline-block;
                @include media-breakpoint-up(lg) {
                    width: 180px;
                }
            }

            &:hover {
                background-color: $light-grey2;
                transition: all ease 0.3s;
                padding-top: 14px;
                padding-bottom: 34px;
                @include media-breakpoint-down(xl) {
                    padding: 20px 40px 20px 20px;
                }
                &:after {
                    content: "";
                    opacity: 1;
                    right: 15px;
                    transition: all ease 0.3s;
                }
            }
        }
    }
    .menu-child {
        display: none;
        top: 150px;
        z-index: 1;
        position: absolute;
        background-color: white;
        border-radius: 8px;
        box-shadow:
            0px 14px 64px -25px rgba(13, 30, 70, 0.15),
            0px 8px 22px -6px rgba(13, 30, 70, 0.1);
        @include media-breakpoint-down(xl) {
            display: none !important;
        }
        &:before {
            content: "";
            height: 12px;
            width: 100%;
            display: block;
            left: 0;
            top: -12px;
            position: absolute;
        }
        .item-child {
            display: none;
            .item {
                width: 33.33%;
                background-color: white;
                border-radius: 8px;
                transition: all ease 0.3s;
                float: left;
                @include media-breakpoint-down(lg) {
                    width: 50%;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                a {
                    font-size: 16px;
                    width: 100%;
                    display: inline-block;
                    position: relative;
                    &:after {
                        content: "";
                        background-image: url(../img/icon/arr_b.svg);
                        height: 16px;
                        width: 16px;
                        display: inline-block;
                        transform: rotate(270deg);
                        position: absolute;
                        right: 20px;
                        top: calc(50% - 8px);
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        transition: all ease 0.3s;
                    }
                }
                &:hover {
                    background-color: $light-grey2;
                    transition: all ease 0.3s;
                    a {
                        &:after {
                            content: "";
                            right: 15px;
                            transition: all ease 0.3s;
                        }
                    }
                }
            }
        }
    }
}
