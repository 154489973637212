.block-slider-actualite-ressource {
    .swiper-slide {
        border-radius: 8px;
        border: 1px solid #cbdaeb;
        background: white;
        overflow: hidden;
        height: 488px;
        position: relative;
        transition: all ease 0.3s;
        &:after {
            content: "";
            height: 25px;
            width: 25px;
            position: absolute;
            right: 20px;
            bottom: 20px;
            transition: all ease 0.3s;
            background-image: url(../img/icon/arrow_right.svg);
        }

        .bg-img {
            height: 160px;
            width: 100%;
            overflow: hidden;
            display: block;
            .bg {
                height: 160px;
                width: 100%;
                display: block;
                background-size: cover;
                background-position: center;
                transition: all ease 0.3s;
                background-color: #cbdaeb;
                @include media-breakpoint-down(lg) {
                    background-size: contain;
                }
            }
        }
        .details {
            display: block;
            .cat {
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                color: $dark-blue;
                display: inline-block;
                border-radius: 4px;
            }
            h3 {
                font-weight: 600;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .content {
                font-size: 18px;
                line-height: 28px;
                display: block;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                box-shadow:
                    0px 14px 28px -6px rgba(13, 30, 70, 0.12),
                    0px 24px 88px -4px rgba(13, 30, 70, 0.14);
                transition: all ease 0.3s;
                &:after {
                    content: "";
                    right: 10px;
                    transition: all ease 0.3s;
                }
                .bg {
                    transform: scale(1.1);
                    transition: all ease 0.3s;
                }
            }
        }
    }
    .swiper-button-ressources {
        position: relative;
        width: 125px;
        height: 48px;
        display: flex;
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid $blue;
            background: white;
            &:before {
                content: "";
                height: 24px;
                width: 24px;
                display: block;
                background-image: url("../img/icon/arr-r-bl.svg");
            }
            &:after {
                content: none;
            }
        }
        .swiper-button-prev {
            left: 0;
            &:before {
                transform: rotate(-180deg);
            }
        }
        .swiper-button-next {
            right: 0;
            left: initial;
        }
    }
}
