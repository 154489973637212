body {
    &.menu-active {
        overflow: hidden;
    }
    .site-container {
        position: relative;
    }
}

/*
|
| Header
|---------
*/
%link-menu {
    text-transform: uppercase;
    color: $very-dark-grey;
    font-size: 14px;
    font-weight: 400;
}

#topmenu {
    padding: 7px 0;
    svg {
        width: 200px;
    }
    .rs-top {
        .icons {
            text-align: right;
            .icon {
                display: inline-block;
                a {
                    height: 32px;
                    width: 32px;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    img {
                        max-width: 28px;
                        max-height: 17px;
                        margin: auto;
                    }
                }
            }
        }
    }
}
.no-scroll {
    overflow: hidden;
}
#header {
    width: 100%;
    position: fixed;
    top: -48px;
    z-index: 10;
    background: white;
    transition: top 0.3s ease;
    &:before {
        content: "";
        height: 100px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: -100px;
        background-color: $black2;
    }
    &.top-header {
        top: 0;
        transition: top 0.3s ease;
    }
    &.header-hidden {
        @include media-breakpoint-up(xl) {
            top: -101px;
        }
        @include media-breakpoint-down(xl) {
            top: -48px;
        }
    }
    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        @include media-breakpoint-down(xl) {
            justify-content: initial;
        }
        .item-logo {
            padding: 13px 0 14px 0;
            @include media-breakpoint-down(lg) {
                position: relative;
                margin-left: 0;
                margin-top: 0;
            }
            img {
                height: 25px;
                max-width: 200px;
            }
        }
        .item-nav {
            .item-menu {
                display: flex;
                li {
                    cursor: pointer;
                    &.menu-item-has-children {
                        position: relative;
                        &:after {
                            content: "";
                            height: 16px;
                            width: 16px;
                            display: block;
                            float: right;
                            margin: 7px 0 0 7px;
                            transform: rotate(0deg);
                            transition: all ease 0.4s;
                        }
                        &.active {
                            &:after {
                                content: "";
                                transform: rotate(-180deg);
                                transition: all ease 0.4s;
                            }
                        }
                    }
                    .nav-drop {
                        display: none;
                        position: absolute;
                    }
                }
            }
            @include media-breakpoint-up(xl) {
                display: flex;
                align-items: center;

                .item-menu {
                    margin-right: 30px;
                }
            }
        }
    }
    .btn-menu-top {
        position: relative;
        @include media-breakpoint-up(xl) {
            display: none !important;
        }
        &:after {
            content: "";
            height: 24px;
            width: 24px;
            background-image: url(../img/icon/arr_b_big.svg);
            display: inline-block;
            position: absolute;
            left: 10px;
            top: calc(50% - 10px);
            display: flex;
            vertical-align: middle;
            align-items: center;
            transition: all ease 0.3s;
            opacity: 1;
            background-size: cover;
            background-position: center;
            background-color: transparent;
            transform: rotate(90deg);
        }
    }
    .topmenu-menu {
        background-color: $white;
        min-height: 48px;
        .item-link {
            color: $black;
            font-weight: 400;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
        }
        .item-nav {
            @include media-breakpoint-down(xl) {
                display: none;
            }
            .item-menu {
                display: flex;

                li {
                    &:last-child {
                        margin-right: 0;
                    }
                    &.menu-item-has-children {
                        &:after {
                            content: "";
                            background-image: url(../img/icon/arr_b.svg);
                        }
                    }
                    &.ic-link-externe {
                        &:after {
                            content: "";
                            height: 16px;
                            width: 16px;
                            display: block;
                            float: right;
                            margin: 7px 0 0 8px;
                            background-image: url(../img/icon/ext_link_b.svg);
                        }
                    }
                }
            }
        }
        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;

            .item-menu {
                margin-right: 25px;
            }
        }
    }
    .btn-menu {
        position: absolute;
        top: 58px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        cursor: pointer;
        background: white;
        height: 48px;
        width: 48px;
        border-radius: 8px;
        box-shadow:
            0px 8px 16px -4px rgba(13, 30, 70, 0.12),
            0px 6px 8px -4px rgba(13, 30, 70, 0.16);
        &:after {
            content: "MENU";
            position: absolute;
            bottom: 1px;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            text-align: center;
        }
        & > .item-burger {
            display: block;
            width: 20px;
            margin-bottom: 8px;

            & > span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $very-dark-grey;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 4px 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .primary-menu {
        height: 48px;
        @include media-breakpoint-down(lg) {
            height: 0;
        }
        .primary-menu-parent {
            overflow: hidden;
            background-color: $black2;
            @include media-breakpoint-down(lg) {
                display: none !important;
            }
            .header-container {
                position: relative;
                &:after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    bottom: 5px;
                    background: white;
                }
                .item-menu {
                    display: flex;
                    li {
                        position: relative;

                        &.menu-item-has-children {
                            &:after {
                                content: "";
                                margin-left: 8px;
                                background-image: url(../img/icon/arr_w.svg);
                            }
                        }
                        .item-link {
                            color: $white;
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 600;
                            font-style: normal;
                            line-height: 24px;
                            position: relative;
                        }
                        &.active,
                        &.current-menu-parent,
                        &.current-menu-ancestor,
                        &.current-menu-item {
                            &:before {
                                content: "";
                                height: 3px;
                                transition: all ease 0.2s;
                            }
                        }

                        &:before {
                            content: "";
                            height: 0px;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            bottom: 5px;
                            background: white;
                            transition: all ease 0.2s;
                        }
                        &:hover,
                        &.current_page_parent {
                            &:before {
                                content: "";
                                height: 3px;
                                transition: all ease 0.2s;
                            }
                        }
                    }
                }
            }
        }
        .primary-menu-child {
            position: absolute;
            left: 0;
            right: 0;
            display: inline-table;
            background: white;
            border-radius: 8px;
            max-height: 0%;
            box-shadow:
                0px 8px 16px -4px rgba(13, 30, 70, 0.12),
                0px 6px 8px -4px rgba(13, 30, 70, 0.16);
            transition: max-height 0.3s ease-in-out;
            @include media-breakpoint-down(lg) {
                display: none !important;
            }
            &.open {
                max-height: 100%;
                transition: max-height 0.3s ease-in-out;
                .pad {
                    padding-block: 48px;
                    transition: all 0.3s ease-in-out;
                }
            }
            .pad {
                padding-block: 0;
                transition: all 0.3s ease-in-out;
            }
            .child-title {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                display: block;
                min-height: 60px;
                color: $black;
                transition: all ease 0.3s;
                span {
                    border-radius: 8px;
                    display: block;
                }
                &:hover {
                    span {
                        background-color: $light-grey2;
                    }
                }
            }
            .item-child {
                display: none;
                transition: max-height 0.3s ease-in-out;
                overflow: hidden;
                max-height: initial;
                .child2 {
                    display: block;
                    &:last-child {
                        padding-bottom: 0;
                    }
                    .child2-title {
                        display: block;
                        font-size: 14px;
                        line-height: 20px;
                        &:hover {
                            color: $black;
                            background-color: $light-grey2;
                            transition: all ease 0.3s;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    padding: var(--header-height) var(--side-margin) 20px var(--side-margin);
    background-color: $white;
    overflow: auto;
    opacity: 1;
    visibility: visible;
    transform: translateX(-100%);
    transition:
        left 0.5s ease,
        opacity 0.5s ease;
    .mobile-menu-header {
        .item-logo {
            position: absolute;
            top: 15px;
            left: 20px;
            img {
                width: 200px;
            }
        }
        .close {
            height: 32px;
            width: 32px;
            display: block;
            position: absolute;
            top: 40px;
            right: 15px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url(../img/icon/close.svg);
        }
    }

    ul.menu-parent {
        width: 100%;
        li {
            position: relative;
            &.item-menu-home {
                display: none;
            }
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            &.menu-item-has-children {
                a {
                    &:after {
                        content: "";
                        height: 24px;
                        width: 24px;
                        background-size: cover;
                        background-image: url(../img/icon/arr_b_big.svg);
                        display: inline-block;
                        position: absolute;
                        right: 10px;
                        top: calc(50% - 12px);
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        transition: all ease 0.3s;
                    }
                    &:hover {
                        &:after {
                            content: "";
                            right: 0px;
                            transition: all ease 0.3s;
                        }
                    }
                }
            }

            a {
                color: $black;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                width: 100%;
                padding: 8px 8px 8px 8px;
                display: block;
                border-radius: 4px;

                &:hover {
                    background: $light-grey2;
                    transition: all ease 0.3s;
                }
            }
        }
    }
}

#mobile-menu2 {
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    right: -100%; /* Cachez le menu sur la droite par défaut */
    padding: var(--header-height) var(--side-margin) 20px var(--side-margin);
    background-color: $white;
    overflow: auto;
    opacity: 1;
    visibility: visible;
    transition:
        right 0.5s ease,
        opacity 0.5s ease;
    .mobile-menu-header {
        .back-childs {
            color: $black;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            position: absolute;
            left: 10px;
            top: 43px;
            display: flex;
            vertical-align: middle;
            align-items: center;
            &:before {
                content: "";
                height: 24px;
                width: 24px;
                background-size: cover;
                transform: rotate(180deg);
                background-image: url(../img/icon/arr_b_big.svg);
                position: relative;
                top: calc(50% - 16px);
                display: flex;
                vertical-align: middle;
                align-items: center;
                transition: all ease 0.3s;
            }
        }
        .close-childs {
            height: 32px;
            width: 32px;
            display: block;
            position: absolute;
            top: 40px;
            right: 15px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url(../img/icon/close.svg);
        }
    }

    ul.menu-child {
        width: 100%;

        .menu-item-has-children {
            padding-bottom: 24px;
        }

        li {
            position: relative;
            &.item-menu-home {
                display: none;
            }
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            span.item-link {
                color: $black;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                display: block;
            }
            a.sub-item-link {
                color: $black;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                width: 100%;
                display: block;
                border-radius: 4px;
                padding: 4px 8px;
            }
            a.sub-sub-item-link {
                color: $black;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
                display: block;
                border-radius: 4px;
                padding: 4px 8px;

                &:hover {
                    background: $light-grey2;
                    transition: all ease 0.3s;
                }
            }
        }

        .nav-drop {
            & > li {
                margin-bottom: 24px;
            }
        }
    }
}
