/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body {
    font-family: $font-family-default;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    color: $default-color;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Poppins;
    font-size: inherit;
    font-weight: inherit;
}

h1 {
    font-weight: 600;
}

button,
input,
select,
textarea {
    border: 0;
    border-radius: 0;

    background-color: transparent;

    appearance: none;

    letter-spacing: inherit;
    font-family: inherit;
    font-size: inherit;
}

.heading3XL {
    font-size: 64px;
    line-height: 72px;
    @include media-breakpoint-down(md) {
        font-size: 48px;
        line-height: 64px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 40px;
    }
}
.heading2XL {
    font-size: 48px;
    line-height: 64px;
    @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: 40px;
    }
}
.headingXL {
    font-size: 40px;
    line-height: 48px;
    @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 36px;
    }
    @include media-breakpoint-down(md) {
        font-size: 22px;
        line-height: 32px;
    }
}
.headingL {
    font-size: 32px;
    line-height: 40px;
    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 32px;
    }
}
.headingM {
    font-size: 24px;
    line-height: 32px;
    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 28px;
    }
}
.headingS {
    font-size: 20px;
    line-height: 28px;
    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 24px;
    }
}
.headingXS {
    font-size: 18px;
    line-height: 24px;
    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 24px;
    }
}
.heading2XS {
    font-size: 16px;
    line-height: 24px;
}

strong {
    font-weight: 600;
}

.d-n {
    display: none;
}

.g-0 {
    gap: 0;
}
.b-600 {
    font-weight: 600;
}
a,
button,
select,
input,
textarea {
    color: inherit;

    &:focus,
    &:focus-visible {
        outline: none;
        outline-offset: 0;
    }
}

a,
button {
    cursor: pointer;
}

button {
    text-transform: inherit;
}

a {
    text-decoration: none;
}
.b {
    font-weight: 600;
}
ol,
ul {
    li {
        list-style-type: none;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}
.c-b {
    color: #153c89 !important;
}
.bs-c {
    background-size: contain !important;
}
img {
    transition: opacity 0.4s ease;

    &[data-src] {
        opacity: 0;
    }
}

.col-auto {
    flex: 1 !important;
    height: initial;
}

.col-xl-auto {
    @include media-breakpoint-up(xl) {
        flex: 1 !important;
        height: initial;
    }
}

.dflex {
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.m-a {
    margin: auto;
}

.txt-c {
    text-align: center;
}

.rel {
    position: relative;
}

.o-h {
    overflow: hidden;
}

.g-24 {
    --gy: 24px;
    --gx: 24px;
}
.gy-0 {
    --gy: 0;
}
.gx-32 {
    --gx: 32px;
}
.bold {
    font-weight: 600;
}
.z1 {
    z-index: 1;
    position: relative;
}
.offset-page {
    padding-top: 100px;
    @include media-breakpoint-down(lg) {
        padding-top: 20px;
    }
}
.bs {
    box-shadow:
        0px 14px 64px -4px rgba(13, 30, 70, 0.08),
        0px 8px 22px -6px rgba(13, 30, 70, 0.04);
}
.o-i {
    overflow: initial !important;
}
.row8 {
    --gx: 8px;
}
.row30 {
    --gy: 30px;
}
.w-100 {
    width: 100%;
}
.fw-600 {
    font-weight: 600;
}
@include media-breakpoint-down(md) {
    .w-100-m {
        width: 100%;
    }
}
.gy-0 {
    --gy: 0;
}
.d-t {
    display: table;
}
.mt--70 {
    margin-top: -70px;
}
.fw-400 {
    font-weight: 400;
}
.bg_grey {
    background-color: $light-grey;
}
.disp-desk {
    display: block !important;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}
.disp-flex-desk {
    display: flex !important;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}
.clearboth {
    clear: both;
}
.disp-mob {
    display: none !important;
    @include media-breakpoint-down(lg) {
        display: block !important;
    }
}
.w100 {
    width: 100%;
}
.c-w {
    color: white;
}
.bg-w {
    background-color: white;
}
.bg-b {
    background-color: #f8fafc;
}
.title-sommary {
    font-size: 24px;
    line-height: 32px;
    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 28px;
    }
}
.markdots {
    height: 700px;
    width: 100%;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: 40px;
    left: inherit;
}
.dot {
    height: 700px;
    width: 700px;
    display: block;
    top: -150px;
    left: -470px;
    @include media-breakpoint-down(lg) {
        opacity: 0.2 !important;
    }
    @include media-breakpoint-down(md) {
        display: none !important;
    }
    &.dot-left {
        position: absolute;
        animation: animdotl 120s linear infinite;
        background-image: url("../img/icon/point-jap-alt.svg");
    }
    &.dot-right {
        position: absolute;
        animation: animdotr 120s linear infinite;
        background-image: url("../img/icon/point-jap.svg");
        right: -17%;
        top: 0px;
        left: inherit;
        @include media-breakpoint-down(xl) {
            right: -27%;
        }
    }
    &.dot-questions {
        position: absolute;
        animation: animdotr 120s linear infinite;
        background-image: url("../img/icon/point-jap-alt.svg");
        right: -390px;
        top: initial;
        bottom: -330px;
        left: inherit;
    }
}

@keyframes animdotl {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes animdotr {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.dots {
    display: flex;
    width: 64px;
    height: 64px;
    @include media-breakpoint-down(lg) {
        margin: auto;
    }
    span {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: white;
        display: block;
        float: left;

        &:not(:last-child) {
            margin-right: 6px;
        }

        @include media-breakpoint-down(xl) {
            height: 12px;
            width: 12px;
        }
    }
    &.dots-alt {
        span {
            background-color: $blue;
        }
    }
}

.title-dots {
    @include media-breakpoint-down(lg) {
        text-align: center;
    }
}

.page-content {
    // padding-top: 100px;
    background: white;
    @include media-breakpoint-down(lg) {
        padding-top: initial;
    }
}

.dot-blue {
    &::before {
        content: "";
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: $blue;
        display: block;
        margin-top: 15px;
        margin-right: 15px;
        float: left;

        @include media-breakpoint-down(md) {
            width: 12px;
            height: 12px;
        }
    }
}
.breadcrumb {
    font-family: Poppins;
    font-size: 14px;
    @include media-breakpoint-down(lg) {
        &.p-mob {
            padding-left: 0;
            padding-top: 38px;
        }
    }
    @include media-breakpoint-down(md) {
        &.p-mob {
            padding-left: 0;
        }
    }
    a {
        text-decoration: underline;
        &:hover {
            color: #6a9fd7;
        }
    }
}
.bgi {
    width: 100%;
    height: 350px;
    display: block;
    background-size: cover;
    background-position: center;
    z-index: 0;
    position: relative;
}
.soon {
    text-align: center;
    display: block;
    margin: 240px auto 300px auto;
}
.offsettop {
    padding-top: 20px;
}
.up {
    z-index: -1;
    opacity: 0;
    height: 64px;
    width: 64px;
    position: fixed;
    // right: 20px;
    // top: calc(50% - 32px);
    right: 40px;
    bottom: 40px;
    border: 1px solid $blue;
    border-radius: 50%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    background-color: white;
    transition: all ease 0.3s;
    box-shadow:
        0px 14px 64px -4px rgba(13, 30, 70, 0.08),
        0px 8px 22px -6px rgba(13, 30, 70, 0.04);
    @include media-breakpoint-down(md) {
        right: 24px;
        bottom: 24px;
    }

    &:before {
        content: "";
        height: 24px;
        width: 24px;
        display: block;
        margin: 0 auto;
        transition: all ease 0.3s;
        background-image: url(../img/icon/up.svg) !important;
    }
    &:hover {
        border: 1px solid black;
        transition: all ease 0.3s;
        &:before {
            content: "";
            height: 24px;
            width: 24px;
            display: block;
            margin: -3px auto 3px auto;
            transition: all ease 0.3s;
            background-image: url(../img/icon/up.svg) !important;
        }
    }
    &.active {
        opacity: 1;
        z-index: 2;
        transition: all ease 0.3s;
    }
}
.page-template-cms,
.page-template-esgseo,
.page-template-succes,
.page-template-esglivreblanc,
.single {
    .primary-menu {
        background-color: $light-grey !important;
        .primary-menu-parent {
            background-color: $light-grey !important;
            .header-container {
                &:after {
                    background: none !important;
                }
                .item-menu {
                    li {
                        &:before {
                            background: $black !important;
                        }
                    }
                }
            }
            .menu-item {
                .item-link {
                    color: $black !important;
                    &:before {
                        background: $black !important;
                    }
                }
                &.menu-item-has-children {
                    &:after {
                        content: "";
                        background-image: url(../img/icon/arr_b.svg) !important;
                    }
                }
            }
        }
    }
}
.page-template-esgseo {
    .primary-menu {
        .btn-menu.mt-space-s {
            background-color: $black !important;
            .item-burger {
                span {
                    background-color: #fff !important;
                }
            }
            &:after {
                color: #fff !important;
            }
        }
    }
    h1 {
        margin-top: 30px;
    }
}
.page-template-esglivreblanc {
    .primary-menu {
        .btn-menu.mt-space-s {
            background-color: $black !important;
            .item-burger {
                span {
                    background-color: #fff !important;
                }
            }
            &:after {
                color: #fff !important;
            }
        }
    }
}
.news-card-tag {
    border-radius: 4px;
    background: #cbdaeb;
    padding: 4px 8px;
    color: $dark-blue;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    display: inline-block;
    margin-right: 5px;
    &:last-child {
        margin-right: 0px;
    }
}
.mar-30 {
    margin-top: -30px;
}
@include media-breakpoint-up(lg) {
    .itemr-space-0 {
        margin-right: 0 !important;
    }
}

.page-template-flexible {
    .sommaire {
        .headingM {
            display: none;
        }
    }
}
