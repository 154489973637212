#footer {
    background: $black;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    @include media-breakpoint-down(md) {
        text-align: left;
        position: relative;
        z-index: 0;
    }
    @include media-breakpoint-down(md) {
        .ft-logo {
            width: 100%;
            max-width: 329px;
        }
    }
    .bloc-footer {
        @include media-breakpoint-down(md) {
            padding-bottom: 18px;
        }
        .row {
            @include media-breakpoint-down(lg) {
                --gx: 0px;
                --gy: 0px;
            }
        }
        .links-footer {
            @include media-breakpoint-down(md) {
                padding-bottom: 0px;
            }
        }
        .rs {
            float: right;
            height: 71px;
            display: flex;
            @include media-breakpoint-down(md) {
                float: left;
                margin: 0 -10px;
            }
            .icon {
                display: flex;
                vertical-align: middle;
                align-items: center;
                float: left;
                margin-left: 25px;
                @include media-breakpoint-down(md) {
                    margin: 0 10px;
                }
                a {
                    height: 32px;
                    width: 32px;
                    border-radius: 50px;
                    background-color: white;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    img {
                        max-width: 28px;
                        max-height: 17px;
                        margin: auto;
                    }
                }
            }
        }
        .item-nav {
            .item-link {
                display: block;
                font-weight: 600;
            }

            @include media-breakpoint-down(md) {
                .item-menu {
                    display: inherit;
                }
            }

            @include media-breakpoint-down(md) {
                .col-auto {
                    flex: initial !important;
                    height: initial;
                    width: 100%;
                    margin-bottom: 2rem;
                }
            }
            .nav-drop-footer {
                li {
                    transition: all ease 0.3s;
                    a {
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        display: block;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 1rem;

                        svg {
                            flex: 0 0 14px;
                        }

                        &:hover {
                            color: $black;
                            background-color: white;
                            transition: all ease 0.3s;
                            svg {
                                path {
                                    fill: #0e1c2c;
                                }
                            }
                        }
                    }
                    &.link-title {
                        a {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .block-copy {
            text-align: center;
            margin: auto;
            border-top: 1px solid #b1b9c1;
            @include media-breakpoint-down(md) {
                display: block;
            }
            li {
                text-align: center;
                display: inline-block;
                .elem {
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    color: white;
                }
            }
        }
    }
}
