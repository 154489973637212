/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| DECLARATION
|----------------
|
*/
$very-dark-grey: #141414;
$dark-grey: #424242;
$grey: #999;
$very-light-grey: #fafafa;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

$black: #0e1c2c;
$black2: #0d1e46;
$blue: #225cb5;
$dark-blue: #153c89;
$light-blue: #dfe8f3;
$light-grey: #f8fafc;
$light-grey2: #f0f5f9;
$light-grey3: #616f7d;
$white: white;

/*
|
| DEFAULTS
|----------------
|
*/
$border-color: $grey;
$text-color: $black;
$default-background-overlay: $black-opacity;

$black: $black;
$black2: $black2;
$blue: $blue;
$dark-blue: $dark-blue;
$light-blue: $light-blue;
$light-grey: $light-grey;
$light-grey2: $light-grey2;
$light-grey3: $light-grey3;
$white: $white;
