/*
|--------------------
|      SINGLE
|--------------------
*/

#page-succes {
    .single-livre-blanc {
        background-color: $light-grey;
        .back {
            position: relative;
            &:before {
                content: "";
                height: 16px;
                width: 16px;
                display: block;
                float: left;
                transform: rotate(90deg);
                margin: 7px 7px 0px 0;
                background-image: url(../img/icon/arr_b.svg);
            }
            &:after {
                content: "";
                height: 1px;
                width: 100%;
                display: block;
                position: absolute;
                bottom: 0;
                background: linear-gradient(90deg, rgba(203, 218, 235, 1) 0%, rgba(255, 255, 255, 0) 100%);
            }
            a {
                color: $black;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-decoration-line: underline;
                @include media-breakpoint-down(lg) {
                    padding: 0 0px 0 45px;
                }
            }
        }
        .couv {
            img {
                margin: auto;
                display: block;
                max-width: 296px;
                width: 100%;
                box-shadow:
                    0px 14px 64px -4px rgba(13, 30, 70, 0.08),
                    0px 8px 22px -6px rgba(13, 30, 70, 0.04);
                @include media-breakpoint-down(md) {
                    max-width: 270px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            .cms {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                p,
                a,
                li {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
        .btn-alt {
            padding: 14px 40px 14px 20px;
            @include media-breakpoint-down(md) {
                padding: 8px 30px 8px 18px !important;
            }
            &:after {
                float: left;
                margin-left: 0;
                margin-right: 10px;
                margin-top: 0;
                right: 5px;
                position: relative;
                transform: rotate(180deg);
            }
            &:hover {
                &:after {
                    margin-left: -5px;
                    margin-right: 15px;
                }
            }
        }
    }
}
