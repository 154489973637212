.block-list-links {
    h2 {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    .links {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        border-radius: 15px;
        border: 1px solid #dfe8f3;
        border-bottom: none;
        border-right: none;

        li {
            height: 120px;
            width: 50%;
            float: left;
            border-right: 1px solid #dfe8f3 !important;
            border-bottom: 1px solid #dfe8f3 !important;
            background-color: white;
            border-bottom: 0;
            line-height: 24px;
            position: relative;
            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &:after {
                content: "";
                height: 25px;
                width: 25px;
                position: absolute;
                right: 20px;
                transition: all ease 0.3s;
                background-image: url(../img/icon/arrow_right.svg);
            }
            &:hover {
                background-color: $light-grey2;
                transition: all ease 0.3s;
                &:after {
                    content: "";
                    right: 15px;
                    transition: all ease 0.3s;
                }
            }
            &.no-link {
                &:after {
                    content: none;
                }
            }
            a {
                width: 100%;
            }
        }
    }
    #voir-plus {
        display: none;
        display: flex;
        min-height: 2.5rem;
        justify-content: center;
        align-items: center;
        color: $blue;
        cursor: pointer;
        position: relative;
        font-weight: 600;
        margin: auto;
        &:before {
            content: "";
            height: 24px;
            width: 24px;
            margin-right: 8px;
            margin-top: 0;
            display: inline-block;
            transition: all ease 0.3s;
            background-position: right;
            background-size: cover;
            background-size: cover;
            background-image: url("../img/icon/plus-b.svg");
        }
    }
}
