/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
    .banner {
        background-color: $black2;
        padding: 140px 0 80px 0;
        @include media-breakpoint-down(md) {
            padding: 110px 0 80px 0;
        }
    }
    .informations {
        position: relative;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: $light-grey;
            position: absolute;
            left: 0;
            right: 0;
            top: 80px;
            bottom: 0;
        }
        .bloc-infos {
            border-radius: 8px;
            background: white;
            z-index: 1;
            position: relative;
            display: flex;
            margin-top: -80px;
            --container-width: calc(1232px + 48px);
            box-shadow:
                0px 14px 64px -4px rgba(13, 30, 70, 0.08),
                0px 8px 22px -6px rgba(13, 30, 70, 0.04);
            .item {
                height: 190px;
                position: relative;
                @include media-breakpoint-down(lg) {
                    height: initial;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -24px;
                    height: 100%;
                    width: 1px;
                    background-color: #cbdaeb;

                    @include media-breakpoint-down(lg) {
                        position: absolute;
                        top: initial;
                        bottom: -20px;
                        left: 0;
                        right: initial;
                        height: 1px;
                        width: 100%;
                        background-color: #cbdaeb;
                    }
                }
                &:last-child {
                    &:after {
                        content: "";
                        background: none;
                    }
                }
                h2 {
                    font-weight: 600;
                }
                .desc {
                    color: $light-grey3;
                    font-size: 16px;
                    line-height: 24px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    @include media-breakpoint-down(lg) {
                        -webkit-line-clamp: initial;
                        margin-bottom: 20px;
                    }
                }
                a {
                    color: $blue;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    width: fit-content;
                    position: absolute;
                    bottom: 0;
                    @include media-breakpoint-down(lg) {
                        position: relative;
                    }
                }
                &:first-child {
                    a {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
                &:nth-child(2) {
                    a {
                        display: flex;
                        &:after {
                            content: "";
                            height: 20px;
                            width: 20px;
                            top: 0px;
                            right: -30px;
                            position: absolute;
                            display: block;
                            transform: rotate(90deg);
                            transition: all ease 0.2s;
                            background-image: url("../img/icon/up.svg");
                        }
                        &:hover {
                            &:after {
                                content: "";
                                right: -33px;
                                transition: all ease 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}
.block-contact {
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    form {
        position: relative;
    }
    .contact-form {
        border-radius: 8px;
        background: white;
        margin-top: -80px;
        z-index: 1;
        position: relative;
        box-shadow:
            0px 14px 64px -4px rgba(13, 30, 70, 0.08),
            0px 8px 22px -6px rgba(13, 30, 70, 0.04);
        @include media-breakpoint-down(lg) {
            padding: 35px 16px;
            box-shadow: none;
        }
        h2 {
            @include media-breakpoint-down(md) {
                strong {
                    clear: both;
                    display: block;
                }
            }
        }
        .icon {
            height: 64px;
            width: 64px;
            display: block;
            background-color: $blue;
            margin: auto;
            border-radius: 50%;
            display: flex;
            vertical-align: middle;
            align-items: center;
            position: absolute;
            top: -32px;
            left: 0;
            right: 0;
            @include media-breakpoint-down(md) {
                height: 48px;
                width: 48px;
                top: -35px;
            }
            img {
                margin: auto;
                @include media-breakpoint-down(md) {
                    height: 24px;
                    width: 24px;
                }
            }
        }
        .charleft {
            padding-top: 0px !important;
        }
        .champs-obligatoires {
            bottom: -33px !important;
        }
    }
}
