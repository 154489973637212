/*
|--------------------
|      NEWS
|--------------------
*/

#news-list {
    .row {
        margin-top: 24px;
        --gx: 24px;
        --gy: 24px;

        .custom-item {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #cbdaeb;
            position: relative;
            display: block;
            transition: all ease 0.3s;
            @include media-breakpoint-down(md) {
                min-height: initial;
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    overflow: hidden;
                    transition: all ease 0.3s;
                    box-shadow:
                        0px 24px 88px -4px rgba(13, 30, 70, 0.14),
                        0px 14px 28px -6px rgba(13, 30, 70, 0.12);
                    .item {
                        &:after {
                            content: "";
                            right: 15px;
                            transition: all ease 0.3s;
                        }
                    }
                }
            }
            .item {
                min-height: 220px;
                display: block;

                &:after {
                    content: "";
                    height: 25px;
                    width: 25px;
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    transition: all ease 0.3s;
                    background-image: url(../img/icon/arrow_right.svg);
                }
                h2 {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .aria-label,
    .swiper-notification {
        display: none;
    }
}

.load-more {
    display: flex;
    min-height: 2.5rem;
    justify-content: center;
    align-items: center;
    color: $blue;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    &:before {
        content: "";
        height: 24px;
        width: 24px;
        margin-right: 8px;
        margin-top: 0;
        display: inline-block;
        transition: all ease 0.3s;
        background-position: right;
        background-size: cover;
        background-size: cover;
        background-image: url("../img/icon/plus-b.svg");
    }
}

#endoflist {
    margin-top: 3px;
}
