.block-menu-other {
    .menu-other {
        background-color: white;
        border-radius: 8px;
        box-shadow:
            0px 14px 64px -25px rgba(13, 30, 70, 0.08),
            0px 8px 22px -6px rgba(13, 30, 70, 0.04);
        @include media-breakpoint-down(sm) {
            margin: 0;
            width: 100%;
        }
        .sommaire {
            ul {
                list-style: none;
                li {
                }
            }
        }
        .item {
            width: 33.33%;
            background-color: white;
            border-radius: 8px;
            transition: all ease 0.3s;
            float: left;
            @include media-breakpoint-down(lg) {
                width: 50%;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            a {
                font-size: 16px;
                width: 100%;
                display: inline-block;
                position: relative;
                &:after {
                    content: "";
                    height: 20px;
                    width: 20px;
                    background-image: url(../img/icon/arr_b_big.svg);
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 10px);
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    transition: all ease 0.3s;
                }
            }
            &:hover {
                background-color: $light-grey;
                transition: all ease 0.3s;
                a {
                    &:after {
                        content: "";
                        right: 15px;
                        transition: all ease 0.3s;
                    }
                }
            }
        }
    }
}
