.block-actualite-ressource {
    .disp-desk {
        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 24px;
            h3 {
                font-weight: 600;
            }
        }
        .cat {
            border-radius: 4px;
            background: #cbdaeb;
            color: $dark-blue;
            padding: 4px 8px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            display: initial;
        }
        .first {
            border-radius: 8px;
            border: 1px solid #cbdaeb;
            display: inline-block;
            overflow: hidden;
            position: relative;
            background: white;
            grid-column: 1/2;
            grid-row: 1/4;
            @include media-breakpoint-down(lg) {
                height: initial;
                margin-bottom: 15px;
            }
            &:after {
                content: "";
                height: 25px;
                width: 25px;
                position: absolute;
                right: 20px;
                bottom: 20px;
                transition: all ease 0.3s;
                background-image: url(../img/icon/arrow_right.svg);
            }
            .bgi {
                height: 240px;
                width: 100%;
                overflow: hidden;
                display: block;
                @include media-breakpoint-down(lg) {
                    height: 170px;
                    width: 33.33%;
                    float: left;
                }
                .bg {
                    height: 240px;
                    width: 100%;
                    background-position: center;
                    background-size: cover;
                    transition: all ease 0.2s;
                    display: block;
                    background-color: #cbdaeb;
                    @include media-breakpoint-down(lg) {
                        height: 170px;
                    }
                }
            }
            .details {
                display: block;
                @include media-breakpoint-down(lg) {
                    width: 66.66%;
                    float: left;
                }
                h3 {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 600;
                }
                .content {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    transition: all ease 0.2s;
                    box-shadow:
                        0px 24px 88px -4px rgba(13, 30, 70, 0.14),
                        0px 14px 28px -6px rgba(13, 30, 70, 0.12);
                    &:after {
                        right: 13px;
                        transition: all ease 0.2s;
                    }

                    .bg {
                        transform: scale(1.05);
                        transition: all ease 0.2s;
                    }
                }
            }
        }
        .others {
            border-radius: 8px;
            border: 1px solid #cbdaeb;
            display: inline-block;
            overflow: hidden;
            width: 100%;
            position: relative;
            background: white;
            display: flex;
            align-items: center;

            &:last-child {
                margin-bottom: 0;
            }
            &:after {
                content: "";
                height: 25px;
                width: 25px;
                position: absolute;
                right: 20px;
                bottom: 20px;
                transition: all ease 0.3s;
                background-image: url(../img/icon/arrow_right.svg);
            }
            .bgi {
                aspect-ratio: 1;
                height: 100%;
                max-width: 33%;
                .bg {
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: cover;
                    background-color: #cbdaeb;
                    transition: all ease 0.2s;
                    display: block;
                }
            }
            .details {
                // width: 66.66%;
                width: calc(100% - 170px);
                float: left;
                display: block;
                h3 {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 600;
                }
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    overflow: hidden;
                    transition: all ease 0.2s;
                    box-shadow:
                        0px 24px 88px -4px rgba(13, 30, 70, 0.14),
                        0px 14px 28px -6px rgba(13, 30, 70, 0.12);
                    &:after {
                        right: 13px;
                        transition: all ease 0.2s;
                    }

                    .bg {
                        transform: scale(1.05);
                        transition: all ease 0.2s;
                    }
                }
            }
        }
    }
    .disp-desk-less-3,
    .disp-mob {
        .swiper-slide {
            border-radius: 8px;
            background: white;
            overflow: hidden;
            height: 488px;
            position: relative;
            transition: all ease 0.3s;
            @include media-breakpoint-down(lg) {
                height: 400px;
            }
            &:before {
                content: "";
                border-radius: 8px;
                border: 1px solid #cbdaeb;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            &:after {
                content: "";
                height: 25px;
                width: 25px;
                position: absolute;
                right: 20px;
                bottom: 20px;
                transition: all ease 0.3s;
                background-image: url(../img/icon/arrow_right.svg);
            }

            .bg-img {
                height: 160px;
                width: 100%;
                overflow: hidden;
                display: block;
                .bg {
                    height: 160px;
                    width: 100%;
                    display: block;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: all ease 0.3s;
                    background-color: #cbdaeb;
                    @include media-breakpoint-down(lg) {
                        background-size: contain;
                    }
                }
            }
            .details {
                display: block;
                .cat {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    color: $dark-blue;
                    display: inline-block;
                    border-radius: 4px;
                }
                h3 {
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 600;
                }
                .content {
                    font-size: 18px;
                    line-height: 28px;
                    display: block;
                }
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    box-shadow:
                        0px 14px 28px -6px rgba(13, 30, 70, 0.12),
                        0px 24px 88px -4px rgba(13, 30, 70, 0.14);
                    transition: all ease 0.3s;
                    &:after {
                        content: "";
                        right: 10px;
                        transition: all ease 0.3s;
                    }
                    .bg {
                        transform: scale(1.1);
                        transition: all ease 0.3s;
                    }
                }
            }
        }
        .swiper-button {
            position: relative;
            width: 125px;
            height: 52px;
            display: flex;
            .swiper-button-prev,
            .swiper-button-next {
                position: relative;
                height: 48px;
                width: 48px;
                border-radius: 50%;
                border: 1px solid $blue;
                background: white;
                &:before {
                    content: "";
                    height: 24px;
                    width: 24px;
                    display: block;
                    background-image: url("../img/icon/arr-r-bl.svg");
                }
                &:after {
                    content: none;
                }
            }
            .swiper-button-prev {
                left: 0;
                &:before {
                    transform: rotate(-180deg);
                }
            }
            .swiper-button-next {
                right: 0;
                left: initial;
            }
        }
    }
}
