.cta_footer {
    color: white;
    text-align: center;
    background-color: $dark-blue;
    .newsletters {
        .gform_heading {
            display: none;
        }
        form {
            width: 430px;
            height: 48px;
            margin: auto;
            display: flex;
            vertical-align: middle;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #cbdaeb;
            background-color: $white;
            position: relative;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            .gform-body {
                width: 100%;
                color: $light-grey3;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                input[type="text"] {
                    width: calc(100% - 8px);
                    margin: 0 3px;
                    border-radius: 8px;
                }
                input[type="email"] {
                    padding: 8px 16px;
                    color: $black;
                }
            }
            .gform_footer {
                margin: 0;
                padding: 0;
                float: right;
                display: block;
                position: absolute;
                right: 3px;
                input[type="submit"],
                button[type="submit"] {
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    border-radius: 8px;
                    background: $blue;
                    padding: 8px 20px;
                    margin: 0;
                    cursor: pointer;
                    transition: all ease 0.3s;

                    position: relative;
                    background-size: auto;
                    background-repeat: no-repeat;
                    background-image: url(../img/icon/arrow_right_w.svg);

                    @include media-breakpoint-up(sm) {
                        background-position: calc(100% - 20px) center;
                        padding-right: 3rem;
                    }

                    @include media-breakpoint-down(sm) {
                        text-indent: -9999px;
                        height: 40px;
                        background-position: center;
                    }
                    &:hover {
                        background-color: $dark-blue;
                        transition: all ease 0.3s;
                    }
                }
            }
            .validation_message {
                display: none;
            }
            .gfield_error [aria-invalid="true"] {
                border: none;
            }
            .gform_ajax_spinner {
                position: absolute;
                margin-top: 0;
                margin-left: 5px;
            }
        }
    }
}
