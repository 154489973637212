.modal {
    $modal: &;

    display: none;

    position: fixed;
    inset: 0;
    z-index: 1000;

    padding: var(--side-margin);

    overflow: auto;
    transition: background-color 0.5s ease;

    &-inner {
        padding: var(--side-margin);
        margin: auto;

        background-color: white;

        color: black;

        opacity: 0;
        transform: translateY(var(--side-margin));
        transition:
            opacity 0.5s ease,
            transform 0.5s ease;
    }

    // Modals styling
    &[role="dialog"] {
        #{$modal}-inner {
            width: 100%;
            max-width: 54rem;
        }
    }

    // Alerts styling
    &[role="alertdialog"] {
        #{$modal}-inner {
            width: fit-content;
        }
    }

    &[data-opened="true"] {
        background-color: rgba(black, 0.9);

        #{$modal}-inner {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
