.block-temoignages {
    @include media-breakpoint-up(lg) {
        .oneitemonly {
            margin-bottom: 110px;
        }
    }
    .disp-desk {
        .block-testimonials {
            gap: 24px;
            .testimonials {
                width: 50%;
                float: left;
                justify-content: flex-end;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                &.even {
                    margin-top: -120px;
                    @include media-breakpoint-down(lg) {
                        margin-top: initial;
                    }
                }
                .text {
                    background-color: white;
                    border: 1px solid #cbdaeb;
                    overflow: hidden;
                    border-radius: 8px;
                    .content {
                        font-size: 18px;
                        line-height: 28px;
                    }
                    .details {
                        .bg {
                            border-radius: 50%;
                            height: 40px;
                            width: 40px;
                            display: block;
                            float: left;
                            background-size: cover;
                            background-position: center;
                        }
                        .infos {
                            float: left;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            .name {
                                font-weight: 600;
                                color: $black;
                            }
                            .role {
                                color: $light-grey3;
                            }
                        }
                    }
                }
                .video-container {
                    position: relative;
                    height: 281px;
                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        height: 285px;
                    }
                    iframe {
                        border-radius: 8px;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: opacity 0.3s;
                    }
                }
            }
        }
    }
    .disp-mob {
        overflow: hidden;
        .swiper-testimonials {
            .swiper-slide {
                border-radius: 8px;
                background: white;
                overflow: hidden;
                position: relative;
                min-height: 282px;
                transition: all ease 0.3s;
                @include media-breakpoint-down(sm) {
                    min-height: initial;
                }
                &:before {
                    content: "";
                    border-radius: 8px;
                    border: 1px solid #cbdaeb;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
                .video {
                    position: relative;
                    width: 100%;
                    iframe {
                        width: 100%;
                        display: flex;
                        @include media-breakpoint-down(sm) {
                            height: 370px;
                        }
                    }
                }
                .video-cover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 6px;
                    transition: opacity 0.3s;
                }
                .text {
                    line-height: initial;
                }
                .bg-img {
                    height: 160px;
                    width: 100%;
                    overflow: hidden;
                    display: block;
                    .bg {
                        height: 160px;
                        width: 100%;
                        display: block;
                        background-size: cover;
                        background-position: center;
                        transition: all ease 0.3s;
                    }
                }
                .details {
                    display: block;
                    .bg {
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        display: block;
                        float: left;
                        background-size: cover;
                        background-position: center;
                    }
                    .infos {
                        float: left;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        .name {
                            font-weight: 600;
                            color: $black;
                        }
                        .role {
                            color: $light-grey3;
                        }
                    }
                    .content {
                        font-size: 18px;
                        line-height: 28px;
                        display: block;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        box-shadow:
                            0px 14px 28px -6px rgba(13, 30, 70, 0.12),
                            0px 24px 88px -4px rgba(13, 30, 70, 0.14);
                        transition: all ease 0.3s;
                        &:after {
                            content: "";
                            right: 10px;
                            transition: all ease 0.3s;
                        }
                        .bg {
                            transform: scale(1.1);
                            transition: all ease 0.3s;
                        }
                    }
                }
            }
            .swiper-button {
                position: relative;
                width: 125px;
                height: 52px;
                display: flex;
                .swiper-button-prev,
                .swiper-button-next {
                    position: relative;
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    border: 1px solid $blue;
                    background: white;
                    &:before {
                        content: "";
                        height: 24px;
                        width: 24px;
                        display: block;
                        background-image: url("../img/icon/arr-r-bl.svg");
                    }
                    &:after {
                        content: none;
                    }
                }
                .swiper-button-prev {
                    left: 0;
                    &:before {
                        transform: rotate(-180deg);
                    }
                }
                .swiper-button-next {
                    right: 0;
                    left: initial;
                }
            }
        }
    }
}
