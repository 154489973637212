.content-seo {
    .btn {
        padding: 8px 40px 8px 15px;
    }
    a {
        word-wrap: break-word;
    }
    .tti {
        .dots {
            @include media-breakpoint-down(lg) {
                margin: 0;
            }
        }
    }
    .dots {
        padding-bottom: 44px;
        span {
            margin-top: -7px;
        }
    }
}

.block-more {
    .bloc-more {
        @include media-breakpoint-down(md) {
            --gy: 5px;
        }
        .dots {
            margin: 0;
        }
        .description {
            color: #0e1c2c;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .list-user {
            --gx: 30px;
            @include media-breakpoint-down(xl) {
                --gy: 15px;
            }
            .elem {
                border-radius: 8px;
                border: 1px solid #cbdaeb;
                background: #fff;
                position: relative;
                @include media-breakpoint-up(xl) {
                    height: 308px;
                }
                .bg {
                    height: 80px;
                    width: 80px;
                    display: block;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    @include media-breakpoint-down(xl) {
                        float: left;
                        margin-right: 12px;
                    }
                    @include media-breakpoint-down(md) {
                        float: initial;
                        margin-right: 0;
                    }
                }
                .name {
                    color: #0e1c2c;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
                .fonction {
                    color: var(--text-onLight-alternative, #616f7d);
                    text-overflow: ellipsis;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
                .rs {
                    position: absolute;
                    bottom: 20px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 20px;
                        position: relative;
                        bottom: 0;
                    }
                    .email,
                    .phone {
                        color: #0e1c2c;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        img {
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
}

.esg-livre-blanc {
    background-color: #f8fafc;

    .breadcrumb {
        padding: 20px 0px 13px 0px;
        margin-bottom: 60px;
        position: relative;
        @include media-breakpoint-down(lg) {
            display: none;
        }
        &:after {
            content: "";
            height: 1px;
            width: 100%;
            display: block;
            position: absolute;
            bottom: 0;
            background: linear-gradient(90deg, rgb(203, 218, 235) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }
    .couv {
        @include media-breakpoint-down(lg) {
            margin: 40px auto;
            display: block;
        }
        img {
            box-shadow:
                -5px 11px 28px 0px rgba(14, 28, 44, 0.1),
                -22px 46px 51px 0px rgba(14, 28, 44, 0.09),
                -49px 103px 69px 0px rgba(14, 28, 44, 0.05);

            @include media-breakpoint-down(lg) {
                max-height: 300px;
                margin: auto;
                display: block;
            }
        }
    }
    .desc,
    .desc a,
    .desc p,
    .desc li {
        color: #0e1c2c;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .cms ul {
        margin-top: -15px;
        padding: 0 0 25px 25px;
        list-style: none;
    }
}

.popin {
    display: none;
    position: fixed;
    left: 50%;
    width: 740px;
    max-height: 90vh;
    min-width: 400px;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
    box-shadow:
        0px 0px 4px 0px rgba(0, 0, 0, 0.15),
        0px 8px 12px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    top: 50%;

    @include media-breakpoint-down(md) {
        left: 16px;
        top: 24px;
        bottom: 10px;
        transform: initial;
        width: calc(100% - 32px);
        min-width: auto;
        overflow-y: auto;
        height: calc(100% - 45px);
        max-height: initial;
    }
    &.fade-out {
        animation: fadeOut 0.3s ease-in-out;
    }
    .close-popin {
        position: absolute;
        top: 24px;
        right: 24px;
        height: 24px;
        width: 24px;
        cursor: pointer;
        z-index: 1;
        @include media-breakpoint-down(md) {
            top: 17px;
            right: 15px;
        }
    }
    .gfield--type-html {
        display: none;
    }
}
.overlay-form-bb {
    display: none;
    opacity: 0.9;
    background-color: #0e1c2c;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
/* Animation de fade pour le popin */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.page-template-esgoffre,
.page-template-esgpersona {
    .btn-menu {
        margin-top: 20px;
    }
    .esg-offre,
    .esg-persona {
        .banner {
            min-height: 460px;
            padding-top: 100px;
            padding-bottom: 100px;
            @include media-breakpoint-down(xl) {
                min-height: initial;
                padding-bottom: 65px;
            }
            @include media-breakpoint-down(lg) {
                padding-top: 180px;
            }
        }
        .intro {
            color: #fff;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            padding: 25px 0 40px 0;
            @include media-breakpoint-down(lg) {
                padding: 20px 0 32px 0;
                font-size: 20px;
                line-height: 28px;
            }
        }
        .btn-intrp-offre,
        .btn-intrp-persona {
            .btn {
                &:not(:last-child) {
                    margin-right: 24px;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 20px;
                    }
                }
                @include media-breakpoint-down(xs) {
                    margin-right: 0px;
                }
            }
        }
    }
}

.page-template-esgseo {
    .back-esg {
        display: none;
        position: relative;
        @include media-breakpoint-down(lg) {
            display: block;
            &.p-mob {
                padding-left: 0px;
                padding-top: 80px;
            }
        }
        &:before {
            content: "";
            height: 16px;
            width: 16px;
            display: block;
            float: left;
            transform: rotate(90deg);
            margin: 7px 7px 0px 0;
            background-image: url(../img/icon/arr_b.svg);
        }
        &:after {
            content: "";
            height: 1px;
            width: 100%;
            display: block;
            position: absolute;
            bottom: 0;
            background: linear-gradient(90deg, rgba(203, 218, 235, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
        a {
            color: $black;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-decoration-line: underline;
        }
    }
}

.block-valeur {
    .row {
        @include media-breakpoint-down(lg) {
            --gy: 20px;
        }
    }
    .infos-val {
        @include media-breakpoint-down(lg) {
            display: flex;
        }
        .icon {
            height: 96px;
            width: 96px;
            border-radius: 50%;
            background: #fff;
            @include media-breakpoint-down(lg) {
                height: 56px;
                width: 56px;
                min-width: 56px;
                float: left;
                img {
                    width: 40px;
                    height: 40px;
                    margin: auto;
                }
            }
        }
        .title {
            color: #225cb5;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            @include media-breakpoint-down(lg) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .desc {
            color: #0e1c2c;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            @include media-breakpoint-down(lg) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
.page-template-esgoffre,
.page-template-esgpersona {
    .cms {
        u,
        a {
            &:hover {
                opacity: 0.6;
            }
        }
    }
}
.page-template-esgoffre {
    .slider-livres-webinar {
        @include media-breakpoint-down(lg) {
            .swiper-button {
                display: none !important;
            }
        }
    }
    .block-list-links {
        .dots {
            padding-bottom: 0;
        }
        .cms {
            margin-top: 0;
        }
    }
    .block-activites,
    .block-temoignages {
        .dots {
            padding-bottom: 30px;
        }
    }
    .block-more {
        .dots {
            margin-top: 15px;
        }
    }
}

.swiper-container .swiper-slide:last-child {
    margin-right: 0 !important;
}
