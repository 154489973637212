/*
|--------------------
|       404
|--------------------
*/

#page-404 {
    max-height: 100vh;
    .section-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        color: $white;
        text-align: center;
        svg {
            margin: auto;
        }
        .title-404 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            color: #001b2d;
        }
        .text-404 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #00162b;
        }
        .btn {
            padding: 14px 20px;
            &:after {
                content: none;
            }
        }
    }
}
