@mixin generate-displays() {
    @each $label, $val in $displays {
        @include generate-screen-class-from-map('', 'd', $label, (display: $val));
    }

    @each $breakpoint in list-reverse(map-keys($breakpoints)) {
        @include media-breakpoint-down($breakpoint) {
            $prefix: #{$breakpoint}-down;

            @each $label, $val in $displays {
                @include generate-screen-class-from-map($prefix, 'd', $label, (display: $val));
            }
        }
    }
    
    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $prefix: #{$breakpoint}-up;

            @each $label, $val in $displays {
                @include generate-screen-class-from-map($prefix, 'd', $label, (display: $val));
            }
        }
    }
}