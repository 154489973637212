.banner {
    background-size: cover;
    background-position: center;
    background-color: $black2;
    overflow: hidden;
    padding: 140px 0 20px 0;
    @include media-breakpoint-down(md) {
        padding: 145px 0 0px 0;
    }
    &.banner-1-menu-market {
        padding: 140px 0 50px 0;
        @include media-breakpoint-down(xl) {
            padding: 140px 0 50px 0;
        }
    }
    &.banner-2-menu-market {
        padding: 140px 0 70px 0;
        @include media-breakpoint-down(xl) {
            padding: 140px 0 70px 0;
        }
    }
    &.banner-3-menu-market {
        padding: 140px 0 100px 0;
        @include media-breakpoint-down(xl) {
            padding: 140px 0 70px 0;
        }
    }
}

.banner_anim {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    &.banner_anim_home {
        &.disp-desk {
            .grid {
                grid-template-columns: repeat(9, 1fr);
            }
        }
        &.disp-mob {
            .grid {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
    &.banner_anim_page {
        &.disp-desk {
            .grid {
                grid-template-columns: repeat(9, 1fr);
            }
        }
        &.disp-mob {
            .grid {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    .grid {
        display: grid;
        grid-gap: 0;
    }
    .cell {
        aspect-ratio: 1/1;
        overflow: hidden;
    }
}
