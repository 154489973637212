.menu-market {
    z-index: 1;
    position: relative;
    border-radius: 8px;
    background-color: white;
    box-shadow:
        0px 14px 64px -25px rgba(13, 30, 70, 0.08),
        0px 8px 22px -6px rgba(13, 30, 70, 0.04);
    --container-width: calc(1232px + 48px);
    @media (max-width: 960px) {
        --container-width: calc(960px + 48px);
    }
    @media (max-width: 712px) {
        --container-width: calc(712px + 48px);
    }
    @include media-breakpoint-down(xl) {
        margin-top: -80px !important;
    }
    @include media-breakpoint-down(sm) {
        width: calc(100% - 30px);
        margin: auto;
    }

    .item {
        width: calc(33.33% - 20px);
        background-color: white;
        border-radius: 8px;
        transition: all ease 0.3s;
        float: left;
        margin-right: 30px;
        @include media-breakpoint-down(xl) {
            width: 50%;
            margin-right: 0;
        }
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        a {
            font-size: 16px;
            width: 100%;
            display: inline-block;
            position: relative;
            padding-right: 40px;
            &:after {
                content: "";
                height: 20px;
                width: 20px;
                background-image: url(../img/icon/arr_b_big.svg);
                display: inline-block;
                position: absolute;
                right: 20px;
                top: calc(50% - 10px);
                display: flex;
                vertical-align: middle;
                align-items: center;
                transition: all ease 0.3s;
            }
        }
        &:hover {
            background-color: #f1f5f9;
            transition: all ease 0.3s;
            a {
                &:after {
                    content: "";
                    right: 15px;
                    transition: all ease 0.3s;
                }
            }
        }
    }
    .block-mea {
        border-top: 1px solid #cbdaeb;
        width: 100%;
        .mea {
            position: relative;
            transition: all ease 0.3s;
            &:after {
                content: "";
                height: 20px;
                width: 20px;
                background-image: url(../img/icon/arr_b_big.svg);
                display: inline-block;
                position: absolute;
                right: 20px;
                top: calc(50% - 10px);
                display: flex;
                vertical-align: middle;
                align-items: center;
                transition: all ease 0.3s;
            }
            .bg {
                height: 50px;
                width: 50px;
                float: left;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
            }
            .title {
                float: left;
                margin-left: 15px;
            }
            &:hover {
                border-radius: 8px;
                background-color: $light-grey;
                transition: all ease 0.3s;
                &:after {
                    content: "";
                    right: 15px;
                }
            }
        }
    }
}
