.block-nos-offres {
    .selection {
        margin: auto;
        max-width: 680px;
        @include media-breakpoint-down(lg) {
            max-width: 430px;
            padding-block: 30px;
            --gy: 30px;
        }

        .filters-item {
            display: block;
            position: relative;
            z-index: 1;
            --per-row: 3;
            max-width: inherit;
            &:after {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                top: calc(50% - 8px);
                right: 15px;
                transition: all ease 0.3s;
                background-image: url(../img/icon/select.svg);
            }
            select {
                display: block;
                width: 100%;
                padding: 12px 36px 12px 12px;
                border-radius: 8px;
                border: 1px solid #cbdaeb;
                font-size: 16px;
                line-height: 24px;
                color: #89949f;
                cursor: pointer;

                &.open,
                &.selected {
                    border-color: #7CA8DF;
                }

                &.open.selected {
                    color: $black;
                }

                &.selected:not(.open) {
                    border-color: #225CB5;
                    color: $black;
                }
            }
        }
    }
    #lien-url {
        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: 310px;
        }
        &::after {
            background-image: url(../img/icon/arrow_right_w.svg);
        }
        &.disable {
            background: #f0f1f2;
            color: #b1b9c1;
            &:after {
                content: "";
                transition: all ease 0.3s;
                opacity: 0.3;
                background-image: url(../img/icon/arrow_right.svg);
            }
        }
    }
}
